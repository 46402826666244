import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import * as serviceWorker from './serviceWorker';

import { Provider } from "react-redux";
import { store } from 'store'
import { Switch, Route } from "react-router";
import { BrowserRouter, Redirect } from 'react-router-dom'

import Terms from 'routes/terms/Terms'
import Widget from 'routes/widget/Widget'
import Home from 'routes/home/Home'
import Campaign from 'routes/campaign/Campaign'
import Form from 'routes/form/Form'
import Meeting from 'routes/meeting/Meeting'
//import Onboarding from 'routes/onboarding/Onboarding'
import Consultation from 'routes/consultation/Consultation'
import Success from 'routes/success/Success'

import { AnalyticsProvider } from 'components/Analytics'

import 'App.css'

/*
Sentry.init({
  dsn: "https://85a44e6d109c41c08e31d74b90fbdc40@o190756.ingest.sentry.io/4503925740535808",
  //integrations: [new BrowserTracing()],
  integrations: [new BrowserTracing({ tracingOrigins: ["*"] })],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
*/

ReactDOM.render(
  <Provider store={store}>
    <AnalyticsProvider>
      <BrowserRouter>
          <Switch>
            <Route path='/widget' component={Widget}/>
            <Route path='/meetings/:guid' component={Meeting}/>

            {/*<Route path='/onboarding/:guid' component={Onboarding} />*/}
            <Route path='/consultations/:guid' component={Consultation} />
            <Route path='/success' component={Success} />

            <Route path='/locations/:location_guid/services/:service_guid/' component={Campaign}/>
            <Route path='/locations/:location' component={Home}/>

            <Route path='/form-versions/:form_version_guid' component={Form}/>
            <Route path='/form-responses/:form_response_guid' component={Form}/>

            <Route path='/terms/' component={Terms}/>
            <Route path='/' component={Home}/>

            <Redirect path='/consultation/:guid' to="/consultations/:guid" component={Consultation} />
            <Redirect path='/get-started' to="/" /> // redirect clients pointing to old url
          </Switch>
      </BrowserRouter>
    </AnalyticsProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
