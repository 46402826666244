import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import axios from "axios"
import queryString from 'query-string';
import { BarLoader } from 'react-spinners';
import { isBrowser, isMobile } from 'react-device-detect'
import { MdMic, MdMicOff, MdVideocam, MdVideocamOff, MdScreenShare, MdKeyboardBackspace } from 'react-icons/md';
import { Container, Row, Col, Nav, Navbar, NavbarBrand, NavbarToggler, Collapse, NavItem, NavLink, Input, Button } from 'reactstrap';

import {TextField, TextFieldHelperText} from '@rmwc/textfield';
import {ThemeProvider} from '@rmwc/theme';
import {Grid, GridCell} from '@rmwc/grid';
import {LinearProgress} from '@rmwc/linear-progress';
import eyeson, { StreamHelpers, FeatureDetector } from 'eyeson';
import Video from 'components/Video';
import {getConfig, getSubdomain} from 'config'

const ACCESS_KEY_LENGTH = 24;

let subdomain = getSubdomain();
const { apiEndpoint } = getConfig();

const backend = axios.create({
  baseURL: `${apiEndpoint}`
})

const override = `
    display: block;
    margin: 0 auto;
`;

class Meeting extends Component {
  constructor(props) {
    super(props);
    this.setPin = this.setPin.bind(this)
    this.joinMeeting = this.joinMeeting.bind(this)

    this.handleEvent = this.handleEvent.bind(this);
    this.toggleAudio = this.toggleAudio.bind(this);
    this.toggleVideo = this.toggleVideo.bind(this);
    this.toggleScreen = this.toggleScreen.bind(this);
    this.joinMeeting = this.joinMeeting.bind(this);
    this.setCurrentMessage = this.setCurrentMessage.bind(this)
    this.sendCurrentMessage = this.sendCurrentMessage.bind(this)

    this.loadMeeting = this.loadMeeting.bind(this)
    this.disconnect = this.disconnect.bind(this)

    const { guid } = props.match.params
    let queryStringParams = queryString.parse(this.props.location.search)
    const pin = queryStringParams.pin

    this.state = {
      stream: null,
      connecting: true,
      audio: true,
      video: true,
      screen: false,
      videoSrc: null,
      meeting: null,
      connected: false,

      podium: null,
      messages: [],

      meetingId: guid,
      pin: pin,
      joining: false,
      pinError: null,
      users: [],

      currentMessage: '',
    }
  }

  componentDidMount() {
    eyeson.onEvent(this.handleEvent);
    var constraints = { audio: true, video: { width: 640, height: 480 } };
    const scope = this

    if (false) {
      const support = FeatureDetector.canUseEyeson()

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function(mediaStream) {
          scope.setState({local: mediaStream})
          scope.video.muted = true
          scope.video.srcObject = mediaStream
        })
        .catch(function(err) {
          console.log(err.name + ": " + err.message);
        }); // always check for errors at the end.
    }

    const { meetingId } = this.state

    this.loadMeeting(meetingId)
  }

  setPin(event) {
    const pin = event.target.value;
    this.setState({pin})
  }

  loadMeeting(meetingId) {
    backend.get(`/live-meetings/${meetingId}/`)
      .then(response => {
        console.log(response.data)
        this.setState({meeting: response.data})
        if (true) {
          //this.joinMeeting()
        }
      })
      .catch(error => {
        alert(error)
        console.log({error})
      })
  }

  disconnect() {
    window.location.reload()
  }

  getRoom = async (accessKey) => {
    axios.get(`https://api.eyeson.team/rooms/${accessKey}`, {
      headers: {"Access-Control-Allow-Origin": "*"}
    })
      .then(response => {
        const { data } = response
        return data
      })
      .catch(error => {
        console.log({error})
      })
  }

  joinMeeting() {
    this.setState({connecting: true, joining: true});
    const { pin, meetingId } = this.state

    backend.get(`/live-meetings/${meetingId}/join?pin=${pin}`)
      .then(response => {
        const { access_key } = response.data

        if (true) {
          axios.get(`https://api.eyeson.team/rooms/${access_key}`)
            .then(response => {
              const { data } = response
              const { links } = data
              window.location.href = links.gui
              console.log(data)
            })
            .catch(error => {
              console.log({error})
            })
        } else {
          eyeson.start(access_key);
        }
      })
      .catch(error => {
        console.log({error})
        this.setState({pinError: 'Invalid'})
      })
  }

  handleEvent(event) {
    if (event.type === 'room_ready') {
      /*
      eyeson.send({
        type: 'start_recording',
      });
      return;
      */
    }

    if (event.type === 'add_user') {
      const { initial, user } = event
      this.setState({
        users: this.state.users.concat(user)
      })
      return;
    }

    if (event.type === 'podium') {
      const { type, ...podium } = event
      this.setState({podium})
    }

    if (event.type === 'remove_user') {
      const { user } = event
      this.setState({
        users: this.state.users.filter((u) => {
          console.log(u.id, user.id)
          return u.id !== user.id
        })
      })
      return;
    }

    if (event.type === 'chat') {
      const { type, ...message } = event
      this.setState({
        messages: this.state.messages.concat(message)
      })
    }

    if (event.type === 'presentation_ended') {
      eyeson.send({ type: 'start_stream', audio: this.state.audio,
        video: this.state.video });
      this.setState({screen: false});
      return;
    }

    if (event.type === 'error') {
      const { name } = event
      alert(name)
      return;
    }

    if (event.type !== 'accept') {
      console.log(event.type)
      //console.debug('[App]', 'Ignore received event:', event.type);
      return;
    }

    this.video.srcObject = event.remoteStream

    this.setState({
      stream: event.remoteStream,
      connecting: false,
      connected: true,
    });
  }

  toggleAudio() {
    const audioEnabled = !this.state.audio;
    if (audioEnabled) {
      StreamHelpers.enableAudio(this.state.local);
    } else {
      StreamHelpers.disableAudio(this.state.local);
    }
    this.setState({audio: audioEnabled});
  }

  setCurrentMessage(event) {
    const currentMessage = event.target.value;
    this.setState({currentMessage})
  }

  sendCurrentMessage() {
    const { currentMessage } = this.state
    eyeson.send({
      type: 'send_chat',
      content: currentMessage,
    })
    this.setState({currentMessage: ''})
  }

  toggleVideo() {
    const { connected, local, video } = this.state
    if (video) {
      this.state.local.getTracks().forEach(function(track) {
        if (track.readyState == 'live' && track.kind === 'video') {
            track.stop();
        }
      });

      if (connected) {
        eyeson.send({
          type: 'change_stream',
          stream: null,
          video: false,
          audio: this.state.audio,
        });
      }

      this.setState({video: false})

    } else {
      var constraints = { audio: true, video: { width: 640, height: 480 } };
      const scope = this

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function(mediaStream) {
          scope.setState({local: mediaStream})
          scope.video.muted = true
          scope.video.srcObject = mediaStream

          if (connected) {
            eyeson.send({
              type: 'change_stream',
              stream: mediaStream,
              video: false,
              audio: scope.state.audio,
            });
          }

          scope.setState({video: true})
        })
        .catch(function(err) {
          console.log(err.name + ": " + err.message);
        }); // always check for errors at the end.
    }
  }

  toggleScreen() {
    if (!this.state.screen) {
      eyeson.send({ type: 'start_screen_capture', audio: this.state.audio,
                    screenStream: null, screen: true });
      this.setState({screen: true});
    } else {
      eyeson.send({ type: 'stop_presenting' });
    }
  }

  render() {
    const { pin, joining, videoSrc, connected, meeting, connecting, stream, local, users, podium } = this.state

    if (connected) {

      return (
        <div className="w-100 h-100 d-flex">
          <div className="d-flex bg-light flex-column align-items-start" style={{width: '64px'}}>
            <div className="w-100 d-flex pt-4 flex-column align-items-center mb-auto">
              <Button
                checked={this.state.video}
                onClick={this.toggleVideo}
                outline={this.state.video ? false : false}
                color={this.state.video ? "light" : "danger"}
                style={{borderRadius: 15,  }}
                label="Toggle video"
              >
              <h3>{ this.state.video ? <MdVideocam /> : <MdVideocamOff />}</h3>
            </Button>

            <Button
              checked={this.state.audio}
              onClick={this.toggleAudio}
              outline={this.state.audio ? false : false}
              color={this.state.audio ? "light" : "danger"}
              style={{borderRadius: 15,  }}
              label="Toggle audio"
            >
              <h3>{ this.state.audio ? <MdMic /> : <MdMicOff />}</h3>
          </Button>

          <Button
              checked={this.state.screen}
              onClick={this.toggleScreen}
              outline={this.state.screen ? false : false}
              color={this.state.screen ? "danger" : "light"}
              style={{borderRadius: 15 }}
              label="Share screen"
              >
            <h3>{ this.state.screen ? <MdScreenShare /> : <MdScreenShare />}</h3>
          </Button>
            </div>

            <div className="w-100 d-flex pb-4 flex-column align-items-center">

            <Button
                onClick={e => this.disconnect()}
                outline={false}
                color="light"
                size="sm"
                className="mt-3"
                style={{borderRadius: 5 }}
                label="Leave Meeting"
                >
              <h3><MdKeyboardBackspace /></h3>
            </Button>

            </div>
          </div>

          <div className="flex-grow-1 align-items-start" style={{backgroundColor: 'black', 'flex-basis': 0}}>
            <div className="d-flex h-100 align-items-center">
              <div className="d-flex h-100 w-100 align-items-center" style={{'overflow': 'hidden'}}>
                {stream && <Video src={stream} style={{width: '100%', height: 'auto' }} />}
              </div>
            </div>

          </div>
          <div className="bg-light d-flex flex-column align-items-start" style={{width: '250px'}}>
            <div className="w-100 mb-auto p-4">

              <div>
                <h6>Chat</h6>
                <ul>
                {this.state.messages.map((message) =>
                  <li key={message.timestamp}>{message.user.name}: {message.content}</li>
                )}
                </ul>

                  <div>
                    <Input
                      label="Message"
                      block
                      style={{marginBottom: '20px'}}
                      placeholder="Message"
                      value={this.state.currentMessage}
                      onChange={this.setCurrentMessage}
                    />
                    <Button
                      block

                      onClick={() => this.sendCurrentMessage()}
                      >
                        Send</Button>
                  </div>
              </div>
            </div>

            <div className="">
              {local && <Video src={local} />}
            </div>
          </div>
        </div>
      )
    } else {

      return (
        <Container fluid="lg" className="h-100 p-5">

          <Row className="d-flex h-100 align-items-center">

            <Col xs={12} md={{size: 6, offset: 3}}>
              <div className="mt-md-0">
                <div class="d-flex justify-content-center align-items-center">

                  { meeting ? (
                    <div className="w-100 p-4 text-center">
                      <p className="lead text-muted m-0">Live consult with</p>
                      <span className="lead text-muted font-weight-bold">{meeting.organization_user.name}</span>
                      <hr />
                      { joining ? (
                        <Button size="lg" className="mt-4" block disabled>Joining, please wait ...</Button>
                      ) : (
                        <Button size="lg" className="mt-4" block onClick={e => this.joinMeeting()}>Join The Meeting</Button>
                      )}
                    </div>
                  ) : (
                    <div className="w-50">
                        <div className="w-100">
                          <BarLoader
                            sizeUnit={"px"}
                            width={'100%'}
                            color={'#333'}
                            loading={true}
                          />
                        </div>
                    </div>
                  )}
                  </div>
                </div>
            </Col>
          </Row>
        </Container>
      )
    }

    return (
      <div className="main theme brown">
          <Container>
          {!this.state.stream && (
            <Fragment>
              <Input
                label="Pin"
                placeholder="Pin"
                onChange={this.setPin}
                disabled={this.state.connecting}
              />
              <Button
                block
                color="primary text-white"
                disabled={joining}
                onClick={this.joinMeeting}
                className="mt-4">Join</Button>
            </Fragment>
          )}
        </Container>
      </div>
    );
  }
}

export default Meeting
