import React, {Component} from 'react';
import { Container, Row, Col, Error, Alert, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom'
import { BarLoader } from 'react-spinners';
import { withAnalytics } from 'components/Analytics'
import FormattedPhoneNumber from 'components/FormattedPhoneNumber'

import {getConfig, getSubdomain} from 'config'

import { trackPage } from 'components/tracking'
import step1 from 'scss/step1.png'
import step2 from 'scss/step2.png'
import step3 from 'scss/step3.png'
import { CreateForm } from 'components/form/Form'
import GoogleReviews from 'components/GoogleReviews/GoogleReviews'
import Loader from 'components/loader/Loader'
import Video from 'components/video/Video'
import Page from 'routes/Page'
import axios from "axios"
import './Home.css'

let subdomain = getSubdomain();
const { apiEndpoint } = getConfig();

const backend = axios.create({
  baseURL: `${apiEndpoint}`
})

const Step = (props) => {
  return (
    <Col xs="4" md="4" className="step">
      <Row>
        <Col xs="12" className="d-flex justify-content-center">
          <div className="numbered-image">
            <img alt="" src={props.img}/>
          </div>
        </Col>
        <Col className="mt-4" xs="12">
          <div class="text-center" style={{maxWidth:180, margin: '0 auto'}}>{props.children}</div>
        </Col>
      </Row>
    </Col>
  )
}

const Content = ({location, history, organization, setBusiness, business, dropdownOpen, toggleDropdown, step, setStep}) => {
  const firstTouch = window.location.href

  if (business) {
    const place_id = business.google_business_place_id
    const { website } = business
    const formattedWebsite = website && !website.includes('://') ? `//${website}` : website

    return (
      <div className="h-100">

        <div className="d-flex flex-column align-items-start p-5 text-center h-100">

          <div className="w-100">
            <h1>{ organization.landing_page_header }</h1>
            <p className="mt-4 mb-4">{ organization.landing_page_subheader }</p>

            <Col xs={12} className="mt-5 pb-4 d-flex justify-content-center">
              <Row className="steps w-100">
                <Step number="1" alt="" img={step1}>{ organization.landing_page_step_1_text }</Step>
                <Step number="2" alt="" img={step2}>{ organization.landing_page_step_2_text }</Step>
                <Step number="3" alt="" img={step3}>{ organization.landing_page_step_3_text }</Step>
              </Row>
            </Col>

            <div className="mt-4">
              { business && (
              <Dropdown block isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle block caret>
                  Select Your Service
                </DropdownToggle>

                <DropdownMenu style={{width: '100%'}}>
                  { business.campaigns.map(campaign => {
                    return (
                      <Link to={{
                          pathname: `/locations/${business.guid}/services/${campaign.guid}`,
                          state: {
                            firstTouch,
                            business,
                          }
                        }}>
                        <DropdownItem block key={campaign.guid}>{ campaign.name }</DropdownItem>
                      </Link>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>
            )}
            </div>
          </div>

          <div className="mb-auto pb-5"></div>

          <div className="mt-5 w-100">
            { website ? (
              <a href={formattedWebsite} target="_blank">
                <img className="mb-4" src={business.logo} width="120" />
              </a>
            ) : (
              <img className="mb-4" src={business.logo} width="120" />
            )}
            { business.phone_number && <div className="mb-3"><FormattedPhoneNumber>{business.phone_number}</FormattedPhoneNumber></div>}
            <GoogleReviews place_id={place_id} />
          </div>
        </div>

      </div>
    )
  } else {
    return (
      <div className="h-100">

        <div className="d-flex flex-column align-items-start p-5 text-center h-100">

          <div className="w-100">
            <h1>{ organization.name }</h1>
            <p className="mt-4 mb-4">Select your location to begin</p>

            <div className="mt-4">
              { organization && (
              <Dropdown block isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle block caret>
                  Select Your Location
                </DropdownToggle>

                <DropdownMenu style={{width: '100%'}}>
                  { organization.businesses.map(business => {
                    return (
                      <DropdownItem onClick={e => setBusiness(business)} block key={business.guid}>{ business.name }</DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>
            )}
            </div>
          </div>
        </div>
      </div>
    )
  }

}

class Home extends Component {
  constructor(props) {
    super(props)
    const { location, match } = this.props
    const { campaign_sku } = match.params

    let startVideoPlaying = false

    if (location && location.state) {
      console.log({location}, this.props)
      startVideoPlaying = location.state.startVideoPlaying
    }

    this.state = {
      fetching: null,
      step: 'select_business',
      organization: null,
      business: null,

      dropdownOpen: false,
      error: null,
      source: null,
      videoPlaying: false,
      startVideoPlaying
    }

    this.getOrganization = this.getOrganization.bind(this)
    this.setBusiness = this.setBusiness.bind(this)
    this.setStep = this.setStep.bind(this)
    this.toggleDropdown = this.toggleDropdown.bind(this)
  }

  componentDidMount() {
    this.getOrganization()
  }

  setStep = step => {
    this.setState({step})
    const { analytics } = this.props
    const { organization, business } = this.state

    // Send to amplitude
    analytics.trackStep(step, {
      medium: 'landing_page',
      organization,
      business,
    })
  }

  setBusiness = business => {
    this.setState({business}, () => {
      this.setStep('service_selection')
    })
  }

  toggleDropdown() {
    const { dropdownOpen } = this.state
    this.setState({dropdownOpen: !dropdownOpen})
  }

  getOrganization() {
    const { match } = this.props
    const { location } = match.params

    const result = backend.get(`/organizations/subdomain/${subdomain}/`)
      .then(response => {
        const { data } = response
        const organization = data
        const businesses = organization.businesses
        this.setState({ organization })

        // Select business that correlates to "location" url param
        if (businesses.length == 1) {
          this.setBusiness(businesses[0])
        } else if (location) {
          const selectedBusiness = businesses.find(b => b.guid == location)
          this.setBusiness(selectedBusiness)
        } else {
          this.setStep('location_selection')
        }

      })
      .catch(error => {
        console.log({error})
        alert('error')
      })
  }

  render() {
    const { location, history } = this.props
    const { step, videoPlaying, startVideoPlaying, consult_type, organization, business, dropdownOpen, error } = this.state
    const contentProps = {
      location, history, organization, business, step,
      dropdownOpen,
      setBusiness: this.setBusiness,
      setStep: this.setStep,
      toggleDropdown: this.toggleDropdown,
    }

    if (organization) {
      if (organization.welcome_video) {

        return (
          <Container className="home">
            <Row className="h-md-100">
              <Col sm={{size:12, offset: 0}} md={{size:6, offfset: 0}} className="left p-0">
                <div className="viewport">
                  <Video history={history} src={organization.welcome_video.encodings[0].to_path} />
                  <div className="footer">Powered by<br /><strong>Your Virtual Consult</strong></div>
                </div>
              </Col>

              <Col sm={{size:12, offset: 0}} md={{size:6, offfset: 0}} className="right">
                <Content {...contentProps} />
                </Col>
            </Row>
          </Container>
        )
      } else {
        return (
          <Container className="home">
            <Row className="h-100">
              <Col xs={12} md={{size: 10, offset: 1}}>
                <Content {...contentProps} />
              </Col>
            </Row>
          </Container>
        )
      }
    } else {
      return <Loader />
    }
  }
}

export default withAnalytics(Home)
