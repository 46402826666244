import React, {Component} from "react";
import axios from "axios"
import DropZone from "react-dropzone";
import ShowError from "fields/showError";
import { css } from '@emotion/core';
import { MdCloudUpload } from "react-icons/md";
import { IoIosCheckmarkCircle } from 'react-icons/io'
import {Row,Col,Button} from 'reactstrap'
import Loader from 'react-spinners/BarLoader';

import {getConfig} from 'config'

let config = getConfig();
let { apiEndpoint } = config;

const fileMaxSize = 15 * 1000 * 1000; // 10MB

class DropZoneField extends Component {

  constructor(props) {
    super(props)
    this.state = {
      uploading: false,
      tempImage: null,
      percentUploaded: 0,
      file: null,

      button_text: '',
      warnings: [],
    }
    this.handleOnDrop = this.handleOnDrop.bind(this)
    this.handleCustomUpload = this.handleCustomUpload.bind(this)
  }

  handleOnDrop(files) {
    const file = files[0]
    const { onChange } = this.props

    this.setState({
      warnings: [],
      percentUploaded: 0,
      uploading: true,
      tempImage: null,
      file: file,
    })

    let warning = undefined

    let fileName = file.name.toLowerCase()

    if (! (fileName.endsWith('.jpeg') || fileName.endsWith('.jpg') || fileName.endsWith('.png'))) {
      warning = file.name + ' must be a valid image file';
    } else if (file.size > fileMaxSize) {
      warning = file.name + ' cannot exceed 15MB in size';
    }

    if (warning) {
      alert(warning)
      this.setState(previousState => ({
          warnings: [warning],
          uploading: false,
      }));
      onChange(null);

    } else {
      return this.handleCustomUpload(file)
    }
	}

  handleCustomUpload(file) {

    const { onChange } = this.props

    let data = {
      file_name: file.name,
      file_type: file.type,
      file_size: file.size,
    }

    axios.post(`${apiEndpoint}/presign/`, data)
      .then(response => {
        const { url , path } = response.data
        const options = {
          headers: {
            'Content-Type': file.type
          },
          onUploadProgress: progressEvent => {
            let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
            this.setState({percentUploaded: percentCompleted})
          }
        }
        axios.put(url, file, options)
          .then(response => {
            this.setState(previousState => ({
                ...previousState,
                uploading: false,
            }))
            onChange(path)
          })
          .catch(error => {
            this.setState(previousState => ({
                uploading: false,
            }))
          })
      })
      .catch(error => {
        console.log("Error" + error)
        //throw new SubmissionError({ ...error.response.data, _error: 'Please correct the fields below' })
        this.setState(previousState => ({
            uploading: false,
        }))
      })
  }

  render() {
    const { value, label, exampleImage } = this.props
    const { warnings, uploading, percentUploaded, file } = this.state

    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;

    return (
      <DropZone
        className="dropzone rounded"
        multiple={false}
        onDrop={this.handleOnDrop}
      >
      { uploading ? (
        <Row>
          <Col xs={4}>
            <div className="image-preview rounded" style={{backgroundImage: `url(${exampleImage})`}} ></div>
          </Col>
          <Col xs={8} className="d-flex align-items-center justify-content-center">
            <div>
              <div>Uploading ({ percentUploaded }%)</div>
              <div className="pt-1">
                <Loader
                  sizeUnit={"em"}
                  size={0.5}
                  color={'#333'}
                  loading={this.state.loading}
                />
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={4}>
            <div className="image-preview rounded" style={{backgroundImage: `url(${exampleImage})`}} ></div>
          </Col>
          <Col xs={4} className="d-flex align-items-center justify-content-center"><div>{label}</div></Col>
          <Col xs={4} className="d-flex align-items-center justify-content-center">
            { !value && <div><MdCloudUpload style={{ fontSize: '2em' }} /></div>}
            { value && <div><IoIosCheckmarkCircle className="dark-green" style={{ fontSize: '2em' }} /></div>}
          </Col>
        </Row>
      )}
      <Row>
        <Col></Col>
      </Row>
      </DropZone>
    )
  }
}

export default DropZoneField;
