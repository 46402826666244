import { Component } from 'react';
import {getConfig} from 'config'
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

class Page extends Component {

  constructor(props) {
    super(props)
    const { debug, gtmId, gaTrackingId } = getConfig()

    setVh();

    function setVh() {
      // We execute the same script as before
      var vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', vh + "px");
    }

    const { name } = this.props.business
    document.title = name
  }

  trackPage(config) {
    config = config || {}
    const { conversion } = config
    const { debug, gtmId, gaTrackingId } = getConfig()
    const { facebook_pixel_id, adwords_conversion_id, adwords_conversion_label } = this.props.business

    const pagePath = window.location.pathname + window.location.search

    let dataLayer = window.dataLayer || []
    dataLayer.push({
      'event':'pageviewCustomEvent',
      'pagePath': pagePath,
    });

    if (conversion && adwords_conversion_id && adwords_conversion_label) {
      dataLayer.push({
        'event':'conversionEvent',
        'AdwordsConversionId': adwords_conversion_id,
        'AdwordsConversionLabel': adwords_conversion_label
      });
    }

    ReactGA.initialize(gaTrackingId, {
      debug: debug,
      titleCase: false,
    });

    //window.dataLayer.push({"CustomPageView": window.location.pathname + window.location.search})
    ReactGA.pageview(pagePath);

    if (facebook_pixel_id) {
      ReactPixel.init(facebook_pixel_id)
      ReactPixel.pageView();
    }
  }

}

export default Page
