import React, { Component } from 'react';
import { BeatLoader } from 'react-spinners';
import { connect } from 'react-redux';

const override = `
    display: block;
    margin: 0 auto;
`;

class Loader extends Component {

  render() {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <div className="">
          <div className="mt-3"></div>
          <BeatLoader
            css={override}
            sizeUnit={"px"}
            width={250}
            color={'#333'}
            loading={true}
          />
        </div>
      </div>
    )
  }
}

export default Loader
