export const validEmail = mail => {
  var email_filter  = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  return email_filter.test(mail)
}

export function validPhone(phoneNumber) {
    const regEx = /^\+[1-9]\d{10,14}$/;
    return regEx.test(phoneNumber);
};

export const targetCheck = (rule, value) => {
  const { operator, text_value, integer_value, choice_value } = rule
  let pass = true
  let fail_reason = null

  switch (operator) {
    case 'is_empty':
      pass = value == ''
      fail_reason = 'Is not empty'
      break

    case 'is_not_empty':
      pass = value != ''
      fail_reason = 'Cannot be empty'
      break

    case 'text_is':
      break
    case 'text_is_not':
      break
    case 'text_contains':
      break
    case 'text_does_not_contain':
      break

    case 'text_min_chars':
      pass = value.length >= integer_value
      const more_chars = integer_value - value.length
      fail_reason = `Requires ${more_chars} characters`
      break

      case 'text_max_chars':
        break

    case 'checkbox_is_checked':
      pass = value
      fail_reason = 'Required'
      break

    case 'choice_is':
      pass = value == choice_value
      fail_reason = 'Choice is invalid'
      break

    case 'email_is_valid':
      pass = validEmail(value)
      fail_reason = 'Invalid email'
      break

    case 'phone_number_is_valid':
      pass = validPhone(value)
      fail_reason = 'Invalid phone number'
      break
  }

  return {
    pass, fail_reason
  }
}
