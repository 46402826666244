import { createStore, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import logger from "redux-logger"
import { createBrowserHistory } from 'history'
import {getConfig} from 'config'

import {
  routerMiddleware,
} from "react-router-redux";

import reducers from "reducers"; // Or wherever you keep your reducers

const config = getConfig();
const { DEBUG } = config;

// Create a history of your choosing (we're using a browser history in this case)
export const history = createBrowserHistory();

const loggerMiddleware = [
  DEBUG && logger,
].filter(Boolean);

// Build the middleware for intercepting and dispatching navigation actions
const middleware = applyMiddleware(routerMiddleware(history), thunk, ...loggerMiddleware)

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
export const store = createStore(reducers, middleware);
