/*
  Sends events to an AWS Api Gateway which
  forwards them to a Lambda function for enrichment
  and storage in Kinesis Firehose, buffered, and placed in S3.

  Example Events Sent:

    Page -> Load -> Widget
    Widget -> Select Step -> Intro
    Widget -> Select Step -> How It Works
    Widget -> Select Step -> Form
    Widget -> Select Step -> Conversion

    Landing Page -> Select Step -> Intro
    Landing Page -> Select Step -> Form
    Landing Page -> Select Step -> Conversion
*/

import axios from "axios"
import { pickBy, identity } from 'lodash'

let CONFIG = {
  'local': {
    'apiEndpoint': 'https://9li6ogxlx1.execute-api.us-west-2.amazonaws.com',
  },
  'stage': {
    'apiEndpoint': 'https://v3v6p1o2ub.execute-api.us-west-2.amazonaws.com',
  },
  'prod': {
    'apiEndpoint': 'https://y84kx2tyde.execute-api.us-west-2.amazonaws.com',
  }
}
CONFIG['dev'] = CONFIG['local']

const SESSION_ID_COOKIE_NAME = 'yvc_sid'
const SESSION_ID_COOKIE_DURATION_MINUTES = 30

const uuidv4 = () => {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

const writeCookie = (name, value, minutes=30) => {
  var date, expires;
  date = new Date();
  date.setTime(date.getTime()+(minutes * 60 * 1000));
  expires = "; expires=" + date.toGMTString();
  document.cookie = name + "=" + value + expires + "; path=/; SameSite=None; Secure";
}

const readCookie = (name) => {
  var i, c, ca, nameEQ = name + "=";
  ca = document.cookie.split(';');
  for(i=0;i < ca.length;i++) {
      c = ca[i];
      while (c.charAt(0)==' ') {
          c = c.substring(1,c.length);
      }
      if (c.indexOf(nameEQ) == 0) {
          return c.substring(nameEQ.length,c.length);
      }
  }
  return null;
}


class YVCAnalytics {

  constructor(env, session_id=null) {
    this.config = CONFIG[env]

    if (session_id) {
      console.log("YVC constructor set session ID", session_id)
      this.setSessionID(session_id)
    }
  }

  getSessionID() {
    let sessionId = readCookie(SESSION_ID_COOKIE_NAME)

    if (sessionId) {
      return sessionId
    } else {
      console.log("YVC no cookie")
      sessionId = uuidv4()
      this.setSessionID(sessionId)
    }
    return sessionId
  }

  setSessionID(value) {
    writeCookie(SESSION_ID_COOKIE_NAME, value, SESSION_ID_COOKIE_DURATION_MINUTES)
  }

  async track(category, action, label, { organization, business, campaign, consultation } = {}) {
    const { apiEndpoint } = this.config
    const session_id = this.getSessionID()
    const current_url = document.location.href
    const referer_url = document.referrer

    let data = {
      session_id,
      category,
      action,
      label,
      organization,
      business,
      campaign,
      consultation,
      current_url,
      referer_url
    }

    const cleanedData = JSON.parse(JSON.stringify(data));

    const api = axios.create({
      baseURL: apiEndpoint,
    })

    console.log("YVC Event", cleanedData)

    await api.post('/events', data)
      .then(response => {
        const { data } = response
      })
      .catch(error => {
        //console.log("Error", error)
        console.log(error);
      })
  }

}

export default YVCAnalytics
