import React, {Component, memo} from 'react';
import { Container, Row, Col, Error, Alert, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom'
import { BarLoader } from 'react-spinners';
import queryString from 'query-string'

import {getConfig, getSubdomain} from 'config'

import { trackPage } from 'components/tracking'
import step1 from 'scss/step1.png'
import step2 from 'scss/step2.png'
import step3 from 'scss/step3.png'

import { withAnalytics } from 'components/Analytics'
import Form from 'components/form/Form'
import { MdKeyboardArrowRight } from "react-icons/md";
import { FiCamera } from 'react-icons/fi'
import { ImPencil2 } from 'react-icons/im'
import { GrDocumentVideo } from 'react-icons/gr'
import logo from './logo-transparent-200.png'

import Loader from 'components/loader/Loader'
import Video from 'components/video/Video'
import Page from 'routes/Page'
import axios from "axios"
import './Widget.css'

let subdomain = getSubdomain();
const { apiEndpoint } = getConfig();

const backend = axios.create({
  baseURL: `${apiEndpoint}`
})

const Step = (props) => {
  return (
    <Col xs="4" md="4" className="step">
      <Row>
        <Col xs="12" className="d-flex justify-content-center">
          <div className="numbered-image">
            <img alt="" src={props.img}/>
          </div>
        </Col>
        <Col className="mt-4" xs="12">
          <div className="text-center">{props.children}</div>
        </Col>
      </Row>
    </Col>
  )
}

class Home extends Component {
  constructor(props) {
    super(props)
    const { location, match } = this.props
    const { campaign_sku } = match.params

    let startVideoPlaying = false

    if (location && location.state) {
      startVideoPlaying = location.state.startVideoPlaying
      //alert(startVideoPlaying)
    }

    const search = location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const referrer = params.get('referrer'); // bar

    this.state = {
      fetching: null,
      step: 'intro',
      organization: null,
      business: null,
      dropdownOpen: false,
      campaign: null,
      error: null,
      source: null,
      videoPlaying: false,
      startVideoPlaying,
      referrer,
    }

    this.getOrganization = this.getOrganization.bind(this)
    this.setStep = this.setStep.bind(this)
    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.selectCampaign = this.selectCampaign.bind(this)
    this.getThumb = this.getThumb.bind(this)
  }

  componentDidMount() {
    this.getOrganization()
  }

  setStep = step => {
    const { analytics } = this.props
    const { business, organization, campaign } = this.state
    analytics.trackStep(step, {
      medium: 'widget',
      business,
      organization,
      campaign
    })

    this.setState({step})
  }

  toggleDropdown() {
    const { dropdownOpen } = this.state
    this.setState({dropdownOpen: !dropdownOpen})
  }

  toggleStep = step => {
    this.setState({step})
  }

  getOrganization() {
    const result = backend.get(`/organizations/subdomain/${subdomain}`)
      .then(response => {
        const { data } = response
        const organization = data

        const oneBusiness = organization.businesses.length == 1
        let business = oneBusiness ? organization.businesses[0] : null

        this.setState({ organization, business }, () => {
          this.setStep('intro')
        })

        //this.selectCampaign(business.campaigns[0])

        //trackPage({business, config: {conversion: false}})

        //this.setState({source: data.welcome_video.encodings[0].to_path})
        //this.player.load()
      })
      .catch(error => {
        console.log(error)
      })
  }

  selectCampaign = campaign => {
    this.setState({campaign}, () => {
      if (campaign.video) {
        this.setStep('campaign_video')
      } else {
        this.setStep('campaign_form')
      }
    })
  }

  selectBusiness = business => {
    this.setState({business}, () => {
      this.setStep('service_selection')
    })
  }

  getThumb() {
    const { organization } = this.state

    if (organization) {
      const videoEncoding = organization.welcome_video ? organization.welcome_video.encodings[0].to_path : null
      let doctorImage = null; //organization.doctors.length ? organization.doctors[0].image : null;

      if (videoEncoding) {
        return (
          <div className="video-wrapper">
            <Video
              startPlaying
              playsInline
              src={videoEncoding}
            />
          </div>
        )
      } else if(doctorImage) {
        if (doctorImage.startsWith('/media')) {
          doctorImage = 'http://localhost' + doctorImage
        }
        return (
          <img src={doctorImage} className="thumb" />
        )
      }
    }

    return null
  }

  getCampaignVideo(campaign) {
    const videoEncoding = campaign.video.encodings[0].to_path

    if (videoEncoding) {
      return (
        <div className="video-wrapper">
          <Video
            startPlaying
            playsInline
            src={videoEncoding}
          />
        </div>
      )
    }
  }

  render() {
    const { history, analytics } = this.props
    const { step, campaign, referrer, videoPlaying, startVideoPlaying, consult_type, organization, business, dropdownOpen, error } = this.state
    const thumb = this.getThumb()

    if (organization) {
      //const videoEncoding = organization.welcome_video ? organization.welcome_video.encodings[0].to_path : null
      //console.log({videoEncoding})
      //const doctorImage = null; //organization.doctors.length ? business.doctors[0].image : null;

      if (organization) {
        return (
          <div className="widget">

            { step == 'intro' && (
                <div className="intro-step video-step">

                  <div className="preview">
                  { thumb }
                  </div>

                  <div className="bottom">
                    <Button className="start-btn d-flex align-items-center" onClick={e => this.setStep('how_it_works')}>
                      <span className="">Let's Get Started!</span>
                    </Button>
                  </div>

                  <div className="footer">
                    <a href="https://yourvirtualconsult.com" target="_blank" className="logo d-flex align-items-center justify-content-center">
                      <span className="powered-by mr-2">Powered By</span>
                      <img src={logo} />
                      <span className="text ml-2">YOUR <span className="bold">VIRTUAL CONSULT</span></span>
                    </a>
                  </div>
                </div>
            )}

            { step == 'how_it_works' && (
              <div className="expalanation-step p-5 text-center">

                <h3 className="mb-5 p-0">How It Works</h3>

                <div className="mb-5 d-flex justify-content-center">
                  <Row className="steps w-100">
                    <Step number="1" alt="" img={step1}>Snap a Photo</Step>
                    <Step number="2" alt="" img={step2}>Write a Message</Step>
                    <Step number="3" alt="" img={step3}>Get a Video</Step>
                  </Row>
                </div>

                <Button
                  className="p-3"
                  onClick={e => business ? this.setStep('service_selection') : this.setStep('location_selection')}
                  block
                >I'm Ready</Button>
              </div>
            )}

            { step == 'location_selection' && (
              <div className="service-step p-5 text-center">

                      <h3 className="mb-5 p-0">Select Your Location</h3>

                      { organization.businesses.map(business => {
                        return (
                            <Button
                              onClick={e => this.selectBusiness(business)}
                              block
                              key={business.guid}
                            >{ business.name }</Button>

                        )
                      })}
              </div>
            )}

            { step == 'service_selection' && (
              <div className="service-step p-5 text-center">

                      <h3 className="mb-5 p-0">Select a Service</h3>

                      { business.campaigns.map(campaign => {
                        return (

                            <Button
                              onClick={e => this.selectCampaign(campaign)}
                              block
                              key={campaign.guid}
                            >{ campaign.name }</Button>

                        )
                      })}
              </div>
            )}

            { step == 'campaign_video' && (
              <div className="campaign-video-step video-step">

                <div className="preview">
                { this.getCampaignVideo(campaign) }
                </div>

                <div className="bottom">
                  <Button className="start-btn d-flex align-items-center" onClick={e => this.toggleStep('campaign_form')}>
                    <span className="">Start</span>
                  </Button>
                </div>
              </div>
            )}

            { step == 'campaign_form' && (
              <Form
                form_version_guid={campaign.form.active_version.guid}
                campaign={campaign}
                business={business}
                medium='widget'
                onResponseSubmit={(response) => {
                  analytics.trackStep('campaign_form_submitted', {
                    medium: 'landing_page',
                    business,
                    campaign,
                    organization: campaign.organization,
                  })

                  trackPage({business: business, config: {conversion: true}})

                  if (campaign.conversion_url) {
                    window.location.href = campaign.conversion_url
                  } else {
                    history.push({
                      pathname: '/success',
                      state: {
                        business
                      }
                    })
                  }
                }}
                stretch
              />

            )}


          </div>
        )
      }
    } else {
      return <Loader />
    }
  }
}

export default withAnalytics(Home)
