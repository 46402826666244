import React, {Component} from 'react';
import { Container, Row, Col, Error, Alert, Button } from 'reactstrap';
import { BarLoader } from 'react-spinners';
import { isBrowser, isMobile } from 'react-device-detect'

import {getConfig, getSubdomain} from 'config'

import { withAnalytics } from 'components/Analytics'
import Form from 'components/form/Form'
import GoogleReviews from 'components/GoogleReviews/GoogleReviews'
import Loader from 'components/loader/Loader'
import Video from 'components/video/Video'
import Page from 'routes/Page'
import axios from "axios"
import { trackPage } from 'components/tracking'
import step1 from 'scss/step1.png'
import step2 from 'scss/step2.png'
import step3 from 'scss/step3.png'
import './Campaign.css'

let subdomain = getSubdomain();
const { apiEndpoint } = getConfig();

const backend = axios.create({
  baseURL: `${apiEndpoint}`
})

const Step = (props) => {
  return (
    <Col xs="4" md="4" className="step">
      <Row>
        <Col xs="12" className="d-flex justify-content-center">
          <div className="numbered-image">
            <img alt="" src={props.img}/>
          </div>
        </Col>
        <Col className="mt-4" xs="12">
          <div class="text-center" style={{maxWidth:180, margin: '0 auto'}}>{props.children}</div>
        </Col>
      </Row>
    </Col>
  )
}

const Content = ({history, analytics, business, firstTouch, campaign, step, setStep}) => {
  const place_id = business.google_business_place_id
  const { website } = business
  const { organization } = campaign
  const { conversion_url } = campaign
  const formattedWebsite = website && !website.includes('://') ? `//${website}` : website

  return (
    <div className="h-100">

      { step == 'service_overview' && (
        <div className="d-flex flex-column align-items-start p-5 text-center h-100">

          <div className="w-100">
            <h1>{ campaign.name }</h1>
            <p className="mt-4 mb-4">{ campaign.description }</p>

            <Col xs={12} className="mt-5 pb-4 d-flex justify-content-center">
              <Row className="steps w-100">
                <Step number="1" alt="" img={step1}>{ organization.landing_page_step_1_text }</Step>
                <Step number="2" alt="" img={step2}>{ organization.landing_page_step_2_text }</Step>
                <Step number="3" alt="" img={step3}>{ organization.landing_page_step_3_text }</Step>
              </Row>
            </Col>

            <hr className="pt-4" />

            <div class="mt-4 text-center">{ organization.campaign_footer }</div>

            <div className="mt-5">
              <Button onClick={(e) => setStep('campaign_form')} lg color="primary" block>Start <strong>Your Virtual Consult</strong></Button>
            </div>
          </div>

          <div className="mb-auto pb-5"></div>

          <div className="mt-5 w-100">
            { website ? (
              <a href={formattedWebsite} target="_blank">
                <img className="mb-4" src={business.logo} width="120" />
              </a>
            ) : (
              <img className="mb-4" src={business.logo} width="120" />
            )}
            <GoogleReviews place_id={place_id} />
          </div>
        </div>
      )}

      {step == 'campaign_form' && (
        <Form
          form_version_guid={campaign.form.active_version.guid}
          first_touch_url={firstTouch}
          campaign={campaign}
          business={business}
          medium='landing_page'
          onResponseSubmit={(response) => {
            analytics.trackStep('campaign_form_submitted', {
              medium: 'landing_page',
              business,
              campaign,
              organization: campaign.organization,
            })

            trackPage({business: business, config: {conversion: true}})

            if (conversion_url) {
              window.location.href = conversion_url
            } else {
              history.push({
                pathname: '/success',
                state: {
                  business
                }
              })
            }
          }}
          stretch
        />
      )}
    </div>
  )
}

class Campaign extends Component {
  constructor(props) {
    super(props)
    const { location, match, step } = this.props
    const { location_guid, service_guid } = match.params

    let startVideoPlaying = false
    let firstTouch = window.location.href; // Default firstTouch to current url
    let business = null

    if (location && location.state) {
      console.log({location}, this.props)

      startVideoPlaying = location.state.startVideoPlaying
      firstTouch = location.state.firstTouch
      business = location.state.business
      //alert(startVideoPlaying)
    }

    this.state = {
      location_guid,
      fetching: null,
      step: step || 'service_overview',
      location_guid,
      campaign: null,
      business,
      guid: service_guid,
      error: null,
      source: null,
      videoPlaying: false,
      firstTouch,
      startVideoPlaying
    }

    this.getCampaign = this.getCampaign.bind(this)
    this.setStep = this.setStep.bind(this)
  }

  componentDidMount() {
    const { campaign, business } = this.state
    if (business) {
      trackPage({business: business, config: {conversion: false}})
    } else {
      this.getBusiness()
    }
    this.getCampaign()
  }

  setStep = step => {
    const { campaign, business } = this.state
    const { analytics } = this.props

    this.setState({step})
    analytics.trackStep(step, {
      medium: 'landing_page',
      campaign,
      business: business,
      organization: campaign.organization,
    })
  }

  getBusiness() {
    const { location_guid } = this.state

    const result = backend.get(`/businesses/${location_guid}`)
      .then(response => {
        const { data } = response
        const business = data
        this.setState({business})
        trackPage({business: business, config: {conversion: false}})
      })
      .catch(error => {
        console.log(error)
      })
  }

  getCampaign() {
    const { guid, business, startVideoPlaying } = this.state

    const result = backend.get(`/campaigns/${guid}`)
      .then(response => {
        const { data } = response
        const campaign = data
        this.setState({campaign})
        this.setStep('service_overview')
      })
      .catch(error => {
        console.log(error)
      })
  }

  render() {
    const { history, analytics } = this.props
    const { step, business, firstTouch, videoPlaying, startVideoPlaying, consult_type, campaign, error } = this.state

    const formActiveOnMobile = isMobile && step == 'campaign_form'

    if (campaign && business) {
      if (campaign.video) {
        return (
          <Container className="campaign full-width">
            <Row>
                { !formActiveOnMobile && (
                  <Col sm={{size:12, offset: 0}} md={{size:6, offfset: 0}} className="left p-0">
                    <div className="viewport">
                      <Video history={history} src={campaign.video.encodings[0].to_path} />
                      <div className="footer">Powered by<br /><strong>Your Virtual Consult</strong></div>
                    </div>
                  </Col>
                )}

                <Col sm={{size:12, offset: 0}} md={{size: formActiveOnMobile ? 12 : 6, offfset: 0}} className="right">
                  <Content analytics={analytics} firstTouch={firstTouch} business={business} history={history} campaign={campaign} step={step} setStep={this.setStep} />
                </Col>
            </Row>
          </Container>
        )
      } else {
        return (
          <Container className="campaign">
            <Row className="h-100">
              <Col xs={12} md={{size: 10, offset: 1}}>
                <Content analytics={analytics} firstTouch={firstTouch} business={business} history={history} campaign={campaign} step={step} setStep={this.setStep} />
              </Col>
            </Row>
          </Container>
        )
      }
    } else {
      return <Loader />
    }
  }
}

export default withAnalytics(Campaign)
