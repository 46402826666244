import React, { Component } from 'react'
import { Col, FormFeedback } from 'reactstrap'

export const Title = ({ text, subtext, disclaimer }) => (
      <div className="py-5 text-center">
        <h3 className="p-0 m-0">{text}</h3>
        {subtext && <div className="text-center text-muted pt-3">{subtext}</div>}
        {disclaimer && <div className="text-center text-muted pt-1">{disclaimer}</div>}
      </div>
)

export const Step = ({title, isValid, description, errors={}, children, ...props}) => (
  <div {...props}>
    {children}

    {Object.keys(errors).map((key, index) => {
      const val = errors[key]
      return <FormFeedback>{key} {val}</FormFeedback>
    })}
  </div>
)

export const Spacer = () => <div className="">&nbsp;</div>
