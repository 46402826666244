import React, {Component} from 'react';

import parsePhoneNumber from 'libphonenumber-js'

const FormattedPhoneNumber = (props) => {
  const { children } = props
  const parsedPhoneNumber = parsePhoneNumber(children)
  if (parsedPhoneNumber) {
    return (
      <div>
        <a href={parsedPhoneNumber.getURI()}>{parsedPhoneNumber.formatNational()}</a>
      </div>
    )
  } else {
    return <div>{children}</div>
  }

}

export default FormattedPhoneNumber
