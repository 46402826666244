import React, { Component } from 'react'
import { Player, BigPlayButton, ControlBar } from 'video-react';
import { MdPlayCircleFilled } from 'react-icons/md';
import './Video.scss'

class Video extends Component {

  constructor(props) {
    super(props)

    this.state = {
      source: props.src,
      startPlaying: false,
      playing: false,
      playerState: null,
      playTriggered: false,
    }

    this.handleStateChange = this.handleStateChange.bind(this)
    this.play = this.play.bind(this)
  }

  componentDidMount() {
    const { src } = this.props
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    this.play()
  }

  handleStateChange(playerState) {
    // copy player state to this component's state
    const { startPlaying } = this.props
    const { playTriggered } = this.state
    if (startPlaying && !playTriggered && playerState.paused) {
      this.play()
    }

    this.setState({
      playerState,
    });
  }

  play() {
    this.player.seek(0)
    this.player.play()
    this.player.muted = false

    this.setState({
      playTriggered: true,
      playing: true
    })
  }

  render() {
    const { source, startPlaying, playing, playerState, playTriggered } = this.state
    return (
        <Player
          ref={player => {
            this.player = player;
          }}
          src={source}
          controls={false}
          playIcon={null}

          { ...this.props }
        >

          { (!playTriggered || (playTriggered && playerState && playerState.paused)) && (
            <div className="overlay" onClick={this.play}>
              <div className="playBtn"><MdPlayCircleFilled /></div>
            </div>
          )}

          <ControlBar disableCompletely={true} />

        </Player>
    )
  }
}

export default Video
