const CONFIG = {
  'local': {
    'debug': true,
    'apiEndpoint': 'http://localhost/api/v1',
    'googleMapsApiKey': 'AIzaSyBt6Eb5jAq42RnaZcfysyXi8HzKL7AiK5k',
    'stripeApiKey': 'pk_test_lwtUKdVs75NsB7HhDvWKQ2lB',
    'gaTrackingId': 'foo',
    'mixpanelToken': 'b1c06d09e3cfbb1fc3f0349d868b4680',
    'amplitudeToken': '2a02cee6460d3a3e64a29ffcd9cb0275',
    'gtmId': 'UA-123456-7',
    'subdomain': getSubdomain(),
  },
  'stage': {
    'debug': true,
    'apiEndpoint': 'https://dashboard.yourvirtualconsult.io/api/v1',
    'googleMapsApiKey': 'AIzaSyCGBCwDXBUpFxRPD4_WxhSpY83cLHCxXhM',
    'stripeApiKey': 'pk_test_lwtUKdVs75NsB7HhDvWKQ2lB',
    'gaTrackingId': 'UA-131577358-1',
    'mixpanelToken': 'ffb1ed0c42be30c56ea53b2c86e6cf30',
    'amplitudeToken': '9517dc31d06030b54f8f4a06449f6f47',
    'gtmId': 'UA-123456-7',
    'subdomain': getSubdomain(),
  },
  'prod': {
    'debug': false,
    'apiEndpoint': 'https://dashboard.yourvirtualconsult.com/api/v1',
    'googleMapsApiKey': 'AIzaSyBt6Eb5jAq42RnaZcfysyXi8HzKL7AiK5k',
    'stripeApiKey': 'pk_live_o6HwaCRjGby3zLKOdxH8ThfH',
    'gaTrackingId': 'UA-131491850-1',
    'mixpanelToken': 'ca654fdfe947968a77b82f09eef5e4cf',
    'amplitudeToken': 'add3292203fe191e2f96eaa9f3f29e63',
    'gtmId': 'GTM-TPZGZQR',
    'subdomain': getSubdomain(),
  }
}

export function getConfig() {
  let env = process.env['REACT_APP_ENV']
  return CONFIG[env]
}

export function getSubdomain() {
  return window.location.host.split('.')[0]
}
