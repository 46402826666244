import React, { Component } from 'react'
import { Field } from './fields'
import { Container, Row, Col, Button, Progress, Spinner } from 'reactstrap'
import { Step, Title, Spacer } from './components'
import { pruneField } from './fields'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import Video from 'components/video/Video'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { targetCheck, validPhone, validEmail } from './rules'
import axios from "axios"
import {getConfig} from 'config'
import _ from 'lodash';

import './Form.css'

const { apiEndpoint, stripeApiKey } = getConfig();
const backend = axios.create({
  baseURL: `${apiEndpoint}`
})

export function objectValues(obj) {
    let vals = [];
    for (const prop in obj) {
        vals.push(obj[prop]);
    }
    return vals;
}

class Form extends Component {
  constructor(props) {
    super(props)

    const { location, form_version_guid, response_guid } = this.props

    this.state = {
      form_version_guid,
      response_guid,
      form: false,
      steps: [],
      visibleSteps: [],
      values: {},
      showErrors: false,

      canSubmit: false,
      submitting: false,

      currentIndex: 0,
      focusField: undefined,

      stripePromise: null,
    }
  }

  componentDidMount() {
    const { guid, form_version_guid, response_guid } = this.state
    if (response_guid) {
      this.getResponse()
    } else if (form_version_guid) {
      this.getForm()
    }

    const stripePromise = loadStripe(stripeApiKey);
    this.setState({stripePromise})
  }

  getForm = () => {
    const { form_version_guid } = this.state
    backend.get(`forms/${form_version_guid}/`)
      .then(response => {
        const form = response.data
        this.setState({form, steps: form.steps}, () => {
          this.goToIndex(0)
          this.createResponse()
        })
      })
      .catch(error => {
        console.log({error})
        //throw new SubmissionError({ ...error.response.data, _error: 'Please correct the fields below' })
      })
  }

  getResponse = () => {
    const { response_guid } = this.state

    backend.get(`form-responses/${response_guid}/`)
      .then(r => {
        const response = r.data
        console.log({response})
        let {form_version, values} = response
        if (!values) {
          values={}
        }
        this.setState({response, values, steps: form_version.steps, form: form_version}, () => {
          console.log("Values", values)
          this.goToIndex(0)
        })
      })
      .catch(error => {
        console.log({error})
        //throw new SubmissionError({ ...error.response.data, _error: 'Please correct the fields below' })
      })
  }

  createResponse = () => {
    const { form_version_guid } = this.state
    const { organization, business, campaign, medium=null, first_touch_url=null, referrer=null } = this.props
    const data = {
      medium,
      first_touch_url,
      referrer,
      organization: organization ? organization.guid : null,
      business: business ? business.guid : null,
      campaign: campaign ? campaign.guid : null,
    }

    backend.post(`forms/${form_version_guid}/response/`, data)
      .then(response => {
        const data = response.data
        this.setState({response: data})
      })
      .catch(error => {
        console.log({error})
        //throw new SubmissionError({ ...error.response.data, _error: 'Please correct the fields below' })
      })
  }

  updateResponse = () => {
    const { response, values, currentStep } = this.state

    return new Promise ( (resolve, reject ) => {
      if (response) {
        backend.put(`form-responses/${response.guid}/`, {values, current_step: currentStep.guid})
          .then(response => {
            const data = response.data
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  submitResponse = () => {
    const { response, values } = this.state
    return new Promise ( (resolve, reject ) => {
      backend.post(`form-responses/${response.guid}/submit/`, {values})
        .then(response => {

          const data = response.data
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  componentDidUpdate() {
    const { focusField } = this.state

    if (focusField) {
      const element = document.getElementsByName(focusField.guid)[0]
      if (element) {
        element.focus()
      }
      this.setState({focusField: undefined})
    }
  }

  onFieldChange = (name, value, options={}) => {
    const { values, currentStep } = this.state
    const {updateFocus=false, autoProgress=false, updateResponse=false} = options

    console.log("fieldChange", name, value)

    if (value == '') {
      value = null;
    }

    // Update values
    this.setState(
      prevState => ({...prevState, values: {...prevState.values, [name]: value}}),
      () => {
        if (updateFocus) {
          const focusField = this.getFirstBlankStepField(this.state.currentStep)
          this.setState({focusField})
        }

        if (autoProgress && this.state.currentStep.elements.filter(element => this.isVisible(element) && element.element_type.includes('field')).length == 1) {
          this.handleNext()
        }

        if (updateResponse) {
          this.updateResponse()
        }
      }
    )
  }

  onFieldBlur = (e) => {
    const name = e.target.name
    const field = this.getElement(name)

    this.updateResponse()
  }

  onFieldKeyDown = e => {
    if (e.key == 'Enter') {
      e.preventDefault()
      this.handleNext()
    }
  }

  handleBack = () => {
    const { currentIndex, steps } = this.state

    // Identify next visible index
    for (let i = currentIndex-1; i >= 0; i--) {
      const step = steps[i]
      if (this.isVisible(step)) {
        this.goToIndex(i)
        break
      }
    }
  }

  handleNext = () => {
    const { onResponseSubmit } = this.props
    const { currentStep, currentIndex, steps, canSubmit } = this.state
    if (this.currentStepIsValid()) {

      if (canSubmit) {
        this.setState({submitting: true})
        this.submitResponse()
          .then( (response) => {
            onResponseSubmit(response)
          })
          .catch(error => {
            this.setState({submitting: false})
          })

      } else {
        // Identify next visible index
        for (let i = currentIndex+1; i <= steps.length; i++) {
          const step = steps[i]
          if (this.isVisible(step)) {
            this.goToIndex(i)
            this.updateResponse()
            break
          }
        }
      }

    } else {
      this.setState({showErrors: true})
    }
  }

  getElement = (guid) => {
    const { form } = this.state
    for (const step of form.steps) {
      for (const element of step.elements) {
        if (element.guid == guid) {
          return element
        }
      }
    }

    return null
  }

  validateInputAgainstRules = (input, rules) => {
    let conjunctions = rules.map(rule => rule.conjunction)
    let conjunction = conjunctions.includes('and') ? 'and' : 'or'
    let passes = []
    let verdict = true
    const errors = {}

    if (rules.length > 0) {
      for (const rule of rules) {
        const { target, ...ruleProps } = rule
        const targetName = this.getElement(target).name
        const value = this.state.values[target] || ''
        const { pass, fail_reason } = targetCheck(ruleProps, value)

        if (!pass) {
          errors[targetName] = fail_reason
        }

        passes.push(pass)
      }

      if (conjunction == 'and') {
        verdict = !passes.includes(false)
      } else {
        verdict = passes.includes(true)
      }
    }

    return {
      passes: verdict,
      errors,
    }
  }

  isVisible = (input) => {
    const {passes, errors} = this.validateInputAgainstRules(input, input.visibility_rules)
    return passes
  }

  getValidationErrors = input => {
    const { values } = this.state
    const { element_type } = input
    const { validation } = pruneField(element_type, input)
    const pruned = pruneField(element_type, input)
    const { required, valid, min_chars, max_chars, image_file_field_validation } = validation
    const value = values[input.guid]
    let errors = []

    if (element_type) {
      if (element_type.includes('file_field')) {

        if (value) {
          const numChoices = pruned.choices.length
          if (image_file_field_validation == 'require_all' && Object.keys(value).length != numChoices) {
            errors.push('All photos are required')
          }
        } else if (image_file_field_validation == 'require_one') {
          errors.push('At least one photo required')
        }

      } else {
        // Check if required
        if (required && !value) {
          errors.push('Required')
        }

        // Check if valid
        if (element_type.includes('phone') && !validPhone(value)) {
          errors.push('Required')
        }

        if (element_type.includes('email') && !validEmail(value)) {
          errors.push('Must be valid')
        }

        if (['short_text_field', 'long_text_field'].includes(element_type)) {
          if (value && min_chars) {
            const pass = value.length >= min_chars
            const more_chars = min_chars - value.length
            if (!pass) {
              errors.push(`Requires ${more_chars} more characters`)
            }
          }
        }
      }
    }




    return errors
  }

  isValid = (input) => {
    const errors = this.getValidationErrors(input)
    return errors.length == 0
  }

  updateFocus = () => {
    const { steps, currentIndex } = this.state
    const { fields } = steps[currentIndex]

    for (const field of fields) {
      const value = this.state.values[field.guid] || ''
      if (value == '') {
        this.setState({focusField: field.guid})
        break;
      }
    }
  }

  currentStepIsValid = () => {
      const { currentStep } = this.state
      const stepIsValid = this.isValid(currentStep)

      if (stepIsValid) {
        const visibleFieldElements = currentStep.elements.filter(element => element.element_type.includes('field') && this.isVisible(element))
        console.log({visibleFieldElements})

        for (const element of visibleFieldElements) {
          const elementIsValid = this.isValid(element)
          if (!elementIsValid) {
            return false
          }
        }
      }

      return true
    }

  getFirstBlankStepField = (step) => {
    const { values } = this.state
    const fieldElements = step.elements.filter(element => element.element_type.includes('field') && !element.element_type.includes('image_field'))

    for (const field of fieldElements) {
      const value = values[field.guid]
      if (!value) {
        return field
      }
    }

    return undefined
  }

  goToIndex = (index) => {
    let { steps, values } = this.state

    const visibleSteps = steps.filter(q => this.isVisible(q))
    const currentStep = steps[index]
    const focusField = this.getFirstBlankStepField(currentStep)
    const currentStepVisibleIndex = visibleSteps.findIndex((step) => step.guid == currentStep.guid)

    this.setState({
      currentIndex: index,
      currentStep,
      visibleSteps,
      currentStepVisibleIndex,
      focusField,
      canSubmit: currentStepVisibleIndex == visibleSteps.length-1,
      showErrors: false,
    })
  }

  render() {
    const { form, currentStep, stripePromise, currentStepVisibleIndex, visibleSteps, currentIndex, showErrors, submitting, values } = this.state
    const { stretch } = this.props
    const formContainerClassName = stretch ? 'col-12' : 'col-12 col-lg-6 offset-lg-3'



    if (currentStep) {

      // Controls
      const controls = {
        submitting,
        hideBack: currentIndex == 0,
        hasBack: currentIndex > 0,
        hideNext: false,
        hasNext: currentStepVisibleIndex != visibleSteps.length-1,
        canProgress: this.currentStepIsValid() || currentStep.post_validate,
      }

      const progress = {
        show: visibleSteps.length > 1,
        percent: ((currentStepVisibleIndex+1) / visibleSteps.length)*100,
      }

      const visibleElements = currentStep.elements.filter(element => this.isVisible(element))
      console.log({visibleElements})

      const stepValid = this.currentStepIsValid()
      console.log({stepValid})

      const visibleElementTypes = visibleElements.map(e => e.element_type)
      const visibleElementsHasHeaderBlock = visibleElementTypes.includes('header_block')

      const formContainer = (
        <Container className="form h-100">

          {progress.show && (
            <Row>
              <Col className="p-0">
                <div className="w-100">
                  <Progress value={progress.percent}></Progress>
                </div>
              </Col>
            </Row>
          )}

          <Row className="h-100">
            <Col className={formContainerClassName}>
              <form className="h-100 px-4 pt-2 d-flex align-items-start flex-column">

                <div className="steps w-100 mb-5">
                  <Step
                    key={currentStep.guid}
                    title={currentStep.title}
                    description={currentStep.description}
                    errors={showErrors && this.getValidationErrors(currentStep)}
                    className={visibleElementsHasHeaderBlock ? '' : 'mt-5'}
                  >
                    {visibleElements.map((element, index) => {
                      const isField = element.element_type.includes('field')
                      if (isField) {
                        const fieldErrors = objectValues(this.getValidationErrors(element))
                        const { element_type, ...field } = element

                        return (
                          <Field
                            key={element.guid}
                            element_type={element.element_type}
                            onChange={this.onFieldChange}
                            onKeyDown={this.onFieldKeyDown}
                            onBlur={this.onFieldBlur}
                            value={values[element.guid] || ''}
                            disabled={controls.submitting}
                            valid={showErrors && fieldErrors.length == 0}
                            invalid={showErrors && fieldErrors.length > 0}
                            errors={showErrors && fieldErrors}
                            field={field}
                          />
                        )
                      } else if (element.element_type == 'header_block') {
                        return <Title key={element.guid} text={element.block_header_text} subtext={element.block_header_description} />
                      } else if (element.element_type == 'image_block') {
                        return (
                          <div className="d-flex justify-content-center">
                            <img className="w-100" src={element.block_image} />
                          </div>
                        )
                      } else if (element.element_type == 'video_block') {
                        return <Video src={element.block_video.encodings[0].to_path} />
                      } else if (element.element_type == 'text_block') {
                        return (
                          <div
                            key={element.guid}
                            dangerouslySetInnerHTML={{__html: element.block_text}}
                          />
                        )
                      }
                    })}
                  </Step>
                </div>

                <div className="mt-md-0 align-self-center">
                  {!controls.hideBack && controls.hasBack && <Button disabled={controls.submitting} className="bg-white px-4 text-dark mr-4" color="link" onClick={this.handleBack}><IoIosArrowBack />Back</Button>}
                  {!controls.hideNext &&
                    <Button color="primary" disabled={!controls.canProgress || controls.submitting} className="px-4" onClick={this.handleNext}>
                    { controls.submitting ? (
                      <div>
                        Submitting..
                        <Spinner size="sm" className="ml-3">
                          Submitting..
                        </Spinner>
                      </div>
                    ) : (
                      <div>
                      { controls.hasNext ? 'Next' : 'Submit'} <IoIosArrowForward />
                      </div>
                    )}
                    </Button>
                  }
                </div>

                <Spacer />
                <Spacer />
                <Spacer />

              </form>
            </Col>
          </Row>
        </Container>
      )

      if (stripePromise) {
        return (
          <Elements stripe={stripePromise}>
            {formContainer}
          </Elements>
        )
      } else {
        return stripePromise
      }
    } else {
      return (
        <div></div>
      )
    }

  }
}

export default Form
