import React, { Component } from 'react'

import StarRatings from 'react-star-ratings';
import './GoogleReviews.css'

export default class GoogleReviews extends Component {

  constructor(props) {
    super(props)
    this.state = {
      place: null,
    }

    this.getGoogleMaps = this.getGoogleMaps.bind(this)
    this.getPlace = this.getPlace.bind(this)
  }

  componentDidMount() {
    const { place_id } = this.props
    if (place_id) {
      this.getPlace(place_id)
    }
  }

  getPlace = id => {
    this.getGoogleMaps()
      .then((google) => {
        console.log("GOOGLE", {google})

        const service = new google.maps.places.PlacesService(document.createElement('div'))
        var request = {
          placeId: id,
        };

        service.getDetails(request, (place, status) => {
          console.log("google", place)
          this.setState({place})
        })

      })
  }

  getGoogleMaps() {
    // If we haven't already defined the promise, define it
    if (!this.googleMapsPromise) {
      this.googleMapsPromise = new Promise((resolve) => {
        // Add a global handler for when the API finishes loading
        window.resolveGoogleMapsPromise = () => {

          let google = window.google;
          // Resolve the promise
          resolve(google);

          // Tidy up
          delete window.resolveGoogleMapsPromise;
        };

        // Load the Google Maps API
        const script = document.createElement("script");
        const API = 'AIzaSyBt6Eb5jAq42RnaZcfysyXi8HzKL7AiK5k';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&libraries=places&callback=resolveGoogleMapsPromise`;
        script.async = true;
        document.body.appendChild(script);
      });
    }

    // Return a promise for the Google Maps API
    return this.googleMapsPromise;
  }

  render() {
    const { place } = this.state

    if (place) {
      const { rating, user_ratings_total, url } = place

      return (
        <div>
          { rating >= 4.5 && (
            <div>
              <span className="pr-2">{rating.toFixed(1)}</span>
              <StarRatings
                rating={rating}
                starRatedColor="#eebb0a"
                starDimension="20px"
                starSpacing="0px"
                numberOfStars={5}
                name='rating'
              />
            <a href={url} target="_blank" className="pl-2 blue">{user_ratings_total} Google Reviews</a>
            </div>
          )}
        </div>
      )
    } else {
      return <div></div>
    }

  }
}
