import React, { Component } from 'react'
import axios from "axios"
import queryString from 'query-string'
import { Container, Row, Col, Nav, Navbar, NavbarBrand, NavbarToggler, Collapse, NavItem, NavLink, Button, Table } from 'reactstrap';
import { IoLogoFacebook, IoLogoTwitter , IoIosHappy, IoMdCall, IoIosCalendar, IoIosVideocam } from 'react-icons/io'
import { TiSocialInstagram } from 'react-icons/ti'
import { Link } from 'react-router-dom'
import FormattedPhoneNumber from 'components/FormattedPhoneNumber'
import { ToothChart, Calendar, Smile } from 'scss/svgs'

import { Player, BigPlayButton } from 'video-react';

import Loader from 'components/loader/Loader'
import { trackPage } from 'components/tracking'
import Video from 'components/video/Video'
import { getConfig } from 'config'
import './Consultation.css'

const { apiEndpoint } = getConfig();
const backend = axios.create({
  baseURL: `${apiEndpoint}`
})

export const CaseWork = ({title, mode, before, after, image, problem, solution, description}) => {
  return (
    <Row className="case-work mt-5">
      <Col>
        <Row className="d-flex justify-content-center">
          <Col md="6" xs="8" className="text-center">
            <div className="title bg-light mb-0 p-3">{title}</div>
          </Col>
        </Row>

        { mode == 'before_and_after' && (
          <Row>
            <Col className="problem">
              <img alt="" src={before} />
              {problem && solution && <div>
                <h4 className="mt-2 text-left">PROBLEM</h4>
                <p className="text-left">{problem}</p>
              </div>}
            </Col>
            <Col className="solution">
              <img alt="" src={after} />
              {problem && solution && <div>
                <h4 className="mt-2 text-right">SOLUTION</h4>
                <p className="text-right">{solution}</p>
              </div>}
            </Col>
          </Row>
        )}

        { mode == 'single_image' && (
          <Row>
            <Col className="image">
              <img alt="" src={image} />
              {image && <div>
                <p className="mt-2 text-left">{description}</p>
              </div>}
            </Col>
          </Row>
        )}

      </Col>
    </Row>
  )
}

class Consultation extends Component {
  constructor(props) {
    super(props)

    const { location, match } = this.props
    const { guid } = match.params

    this.state = {
      guid,
      place: null,
    }

    this.trackViewed = this.trackViewed.bind(this)
  }

  componentDidMount() {
    this.getConsultation()
  }

  trackViewed (guid) {
    const values = queryString.parse(this.props.location.search)
    console.log(values)
    if (values.e) {
      backend.post(`/consultations/${guid}/opened/`)
        .then((response) => {
          console.log(response)

        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  getGoogleMaps() {
    // If we haven't already defined the promise, define it
    if (!this.googleMapsPromise) {
      this.googleMapsPromise = new Promise((resolve) => {
        // Add a global handler for when the API finishes loading
        window.resolveGoogleMapsPromise = () => {

          let google = window.google;
          // Resolve the promise
          resolve(google);

          // Tidy up
          delete window.resolveGoogleMapsPromise;
        };

        // Load the Google Maps API
        const script = document.createElement("script");
        const API = 'AIzaSyBt6Eb5jAq42RnaZcfysyXi8HzKL7AiK5k';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&libraries=places&callback=resolveGoogleMapsPromise`;
        script.async = true;
        document.body.appendChild(script);
      });
    }

    // Return a promise for the Google Maps API
    return this.googleMapsPromise;
  }

  getGoogleBusiness = id => {
    this.getGoogleMaps()
      .then((google) => {
        console.log("GOOGLE", {google})

        const service = new google.maps.places.PlacesService(document.createElement('div'))
        var request = {
          placeId: id,
          fields: ['rating', 'reviews', 'opening_hours', 'adr_address']
        };

        service.getDetails(request, (place, status) => {
          console.log("google", place)
          this.setState({place})
        })

      })

  }

  getConsultation () {
    const { guid } = this.state

    const result = backend.get(`/consultations/${guid}`)
      .then(response => {
        const { data } = response
        const consultation = data
        this.setState({ consultation })
        const { business } = consultation
        console.log({business})
        const { google_business_place_id } = business
        if (google_business_place_id) {
          this.getGoogleBusiness(google_business_place_id)
        }
        trackPage({business, config: {conversion: false}})
        this.trackViewed(consultation.guid)
      })
      .catch(error => {
        console.log(error)
      })
  }

  render() {
    const { consultation, place } = this.state

    if (consultation) {
      if (consultation.video) {
        return (
          <Container className="consultation">
            <Row>
                <Col sm={{size:12, offset: 0}} md={{size:6, offfset: 0}} className="left p-0">
                  <div class="viewport">
                    <Video className="h-100" src={consultation.video.encodings[0].to_path} />
                    <div className="footer">Powered by<br /><strong>Your Virtual Consult</strong></div>
                  </div>
                </Col>

              <Col sm={{size:12, offset: 0}} md={{size:6, offfset: 0}}>
                <Content consultation={consultation} place={place} />
              </Col>
            </Row>
          </Container>
        )
      } else {
        return (
          <Container className="consultation">
            <Row className="">
              <Col>
                <Content consultation={consultation} place={place} />
              </Col>
            </Row>
          </Container>
        )
      }
    } else {
      return (
        <Loader />
      )
    }
  }
}

const Recommendation = (props) => {
  return (
    <Col xs={12} md={6} className="step">
      <div className="xs-mt-5"></div>
      <div className="icon d-flex justify-content-center align-items-center">
          {React.createElement(props.icon)}
      </div>
      <div class="header mt-3">{props.title}</div>
      <div class="body mt-3">{props.children}</div>
    </Col>
  )
}

const Content = ({consultation, place}) => {
  const { video, organization_user, business, case_work, next_step, num_visits_range_min, num_visits_range_max, price_range_min, price_range_max, show_calendar, text, treatment } = consultation
  const { tooth_pillow_bite, tooth_pillow_anterior, tooth_pillow_crossbite, tooth_pillow_alignment, tooth_pillow_arch_form, tooth_pillow_arch_width, tooth_pillow_smile, tooth_pillow_facial_profile, tooth_pillow_posture, tooth_pillow_possible_frenum_tightness } = consultation

  const toothPillowFields = {
    'Bite': tooth_pillow_bite,
    'Anterior': tooth_pillow_anterior,
    'Crosbite': tooth_pillow_crossbite,
    'Alignment': tooth_pillow_alignment,
    'Arch Form': tooth_pillow_arch_form,
    'Arch Width': tooth_pillow_arch_width,
    'Smile': tooth_pillow_smile,
    'Facial Profile': tooth_pillow_facial_profile,
    'Posture': tooth_pillow_posture,
    'Possible Frenum Tightness': tooth_pillow_possible_frenum_tightness,
  }

  let hasToothPillowFields = false
  for (let field in toothPillowFields) {
    const value = toothPillowFields[field]
    if (value) {
      hasToothPillowFields = true
      break
    }
  }



  console.log({toothPillowFields}, hasToothPillowFields)

  const { facebook_url, twitter_url, instagram_url } = business

  let num_visits_range = "--"

  if (num_visits_range_min && num_visits_range_max) {
    num_visits_range = `Between ${num_visits_range_min} and ${num_visits_range_max}`
  } else if (num_visits_range_min && !num_visits_range_max) {
    num_visits_range = `At least ${num_visits_range_min}`
  } else if (num_visits_range_max && !num_visits_range_min) {
    num_visits_range = `Max of ${num_visits_range_max}`
  }

  let price_range = "--"

  if (price_range_min && price_range_max) {
    price_range = `Between $${parseInt(price_range_min)} and $${parseInt(price_range_max)}`
  } else if (price_range_min && !price_range_max) {
    price_range = `At least $${parseInt(price_range_min)}`
  } else if (price_range_max && !price_range_min) {
    price_range = `$${parseInt(price_range_max)}`
  }

  return (
    <div className="">
        { treatment && (
          <Row>
            <Col xs={12} className="p-5">
              <h1 className="pb-5 text-center">{ `${organization_user.name}'s` }<br />Treatment Plan</h1>
              <p dangerouslySetInnerHTML={{__html: treatment}} className="treatment" />
            </Col>
          </Row>
        )}

        { hasToothPillowFields && (
          <Row className="p-5 text-center">
            <Col>
              <h1 className="mb-5">Doctor's Assessment</h1>
              <Row className="section steps">
                <Table>
                {
                  Object.keys(toothPillowFields).map((key, index) => (
                    <tr>
                      <td className="text-left"><strong>{key}</strong></td>
                      <td className='text-right text-muted'>{toothPillowFields[key]}</td>
                    </tr>
                  ))
                }
                </Table>
              </Row>
            </Col>
          </Row>
        )}

        { !hasToothPillowFields && (
          <Row className="bg-gray p-5 text-center">
            <Col>
              <h1 className="mb-5">My Recommendation</h1>
              <Row className="section steps bg-gray">
                  <Recommendation title="Appointments Needed" icon={Calendar}>
                    {num_visits_range}
                  </Recommendation>
                  <Recommendation title="Price Range" icon={Smile}>
                    {price_range}
                  </Recommendation>
              </Row>
            </Col>
          </Row>
        )}



        {case_work.length > 0 && (
          <div className="py-5">
            <Row>
              <Col xs={{size: 12}} className="px-5 mb-5 text-center">
                <h1 className="">Case Work</h1>

                { case_work.map(function(obj, index) {
                  return (
                    <CaseWork {...obj} />
                  )
                })
              }
              </Col>
            </Row>
          </div>
        )
        }

        <Row className="bg-dark-blue text-white p-5">
          <Col xs={12} className="text-center">
            <h1 className="pb-4">Your Next Step</h1>

            { next_step == 'call' && (
              <div>
                <div className="mb-5">Because of the treatment plan and next steps outlined above, please call our office to schedule an in-office appointment.<br />Please make sure to mention this virtual consult when scheduling.
</div>
                { consultation.phone_number && (
                  <a target="_blank" href={`tel:${consultation.phone_number}`}>
                    <Button block color="primary" className="mt-3 d-flex align-items-center justify-content-center">
                      <div>
                        <h4 className="d-inline"><IoMdCall className="mr-3" /></h4>
                        <span>{ consultation.phone_number }</span>
                      </div>
                    </Button>
                  </a>
                )}
              </div>
            )}

            { next_step == 'receive_call' && (
              <p>Because of the treatment plan and next steps outlined above, our office will follow up with a call to schedule you for an in-office appointment.</p>
            )}

            { next_step == 'schedule_online' && (
              <div>
                <p className="mb-5">Because of the treatment plan and next steps outlined above, please use our 24/7 online schedule to book an in-office { consultation.appointment_type }.<br />You can schedule an appointment by clicking on the link below.</p>

                { consultation.schedule_url && (
                  <a target="_blank" href={consultation.schedule_url}>
                    <Button block color="primary" className="mt-3 d-flex align-items-center justify-content-center">
                      <h4 className="d-inline"><IoIosCalendar className="mr-3" /></h4>
                      <span>My Calendar</span>
                    </Button>
                  </a>
                )}
              </div>
            )}

            { next_step == 'schedule_live_consult' && (
              <div>
                <p className="mb-5">After evaluating the information you sent through, we need to schedule a live virtual consult to do a more in-depth virtual exam.<br />Please confirm a time that works for you to do a 30-minute virtual exam.</p>

                <p>Step 1. Find a time that works</p>
                { consultation.calendar_url && (
                  <a target="_blank" href={consultation.calendar_url}>
                    <Button block color="primary" className="mt-3 d-flex align-items-center justify-content-center">
                      <h4 className="d-inline"><IoIosCalendar className="mr-3" /></h4>
                      <span>My Calendar</span>
                    </Button>
                  </a>
                )}

                { consultation.conference_url && (
                <div>
                  <p className="pt-3">
                    Step 2. Use this link to join the live video consultation:
                  </p>

                  <div class="mt-2">
                    <a target="_blank" href={consultation.conference_url}>
                      <Button block color="primary" className="mt-3 d-flex align-items-center justify-content-center">
                        <h4 className="d-inline"><IoIosVideocam className="mr-3" /></h4>
                        <span>Live Video Consultation</span>
                      </Button>
                    </a>
                  </div>
                </div>
                )}

              </div>
            )}

            { next_step == 'no_action' && (
              <div>
                <p>At this time we do not feel there is any direct next step or follow up required. You do not need to schedule an in-office appointment. If anything changes, please feel free to use our virtual consult platform to update us or schedule time with the office to come in.</p>
              </div>
            )}
          </Col>
        </Row>

        { place && 'opening_hours' in place && (
          <div className="mb-5">
            <Row className="mt-5">
              <Col className="text-center">
                <h1>{ business.name }</h1>
              </Col>
            </Row>

            <Row>
              <Col>
                <p dangerouslySetInnerHTML={{__html: place.adr_address}} className="text-center py-2 address" />
                <hr className="w-25" />
              </Col>
            </Row>

            <Row>
              <Col xs={12} className="px-5">
                {place.opening_hours.weekday_text.map(weekday_text => (
                    <div className="mt-3 text-center">{weekday_text}</div>
                ))}
              </Col>
            </Row>

            { business.phone_number &&
              <Row>
                <Col xs={12} className="px-5">
                  <div className="mt-4 text-center">
                    <FormattedPhoneNumber>{business.phone_number}</FormattedPhoneNumber>
                  </div>
                </Col>
              </Row>
            }
          </div>
        )}

    </div>
  )
}

export default Consultation
