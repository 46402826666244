import React, {Component} from 'react';
import amplitude from 'amplitude-js';
import { getConfig } from 'config'
import YVCAnalytics from 'components/YVCAnalytics'
import ReactPixel from 'react-facebook-pixel';
const qs  = require('qs')

const { amplitudeToken } = getConfig()
console.log({ amplitudeToken })

const facebookOptions = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};

const amplitudeOptions = {
  includeGclid: true,
  includeFbclid: true,
  includeUtm: true,
  includeReferrer: true,
}

const AnalyticsContext = React.createContext('analytics');

class AnalyticsProvider extends Component {

  constructor(props) {
    super(props)
    const urlSearchParams = new URLSearchParams(window.location.search);
    const { deviceId, yvcSid, ...searchParams } = Object.fromEntries(urlSearchParams.entries());
    const isDirect = Object.keys(searchParams).length == 0
    amplitude.getInstance().init(amplitudeToken, null, {deviceId, ...amplitudeOptions});

    let env = process.env['REACT_APP_ENV']
    this.yvc = new YVCAnalytics(env, yvcSid)

    this.state = {
      isInitialStep: true,
      firstTouch: {
        href: window.location.href,
        host: window.location.host,
        path: window.location.pathname,
        search: searchParams,
        isDirect
      },
      isDirect,
    }

    this.trackStep = this.trackStep.bind(this)
  }

  identify = (deviceId) => {
    amplitude.getInstance().init(amplitudeToken, null, {
      deviceId,
      ...amplitudeOptions,
    });
  }

  trackStep = (name, properties={}) => {
    const { isInitialStep, firstTouch } = this.state
    const { medium, organization, business=false, campaign=false } = properties

    // Send to YVC Analytics
    const category = (medium=='widget') ? 'Widget' : 'Landing Page'
    const action = 'Show Step'
    const stepLabel = {
      'intro': 'Intro',
      'how_it_works': 'How It Works',
      'location_selection': 'Location Selection',
      'service_selection': 'Service Selection',
      'service_overview': 'Service Overview',
      'campaign_overview': 'Service Overview',
      'campaign_form': 'Service Form',
      'campaign_form_submitted': 'Thank You',
    }

    const analyticsData = {
      organization: organization.guid,
      business: business ? business.guid : null,
      campaign: campaign ? campaign.guid : null,
    }

    const label = stepLabel[name] || null
    const isConversion = name == 'campaign_form_submitted'

    if (label) {
      //console.log("Analytics", organization, business, campaign)
      this.yvc.track(category, action, label, analyticsData)
    }

    const contextData = {
      source: category,

      organization_name: organization ? organization.name : null,
      organization_guid: organization ? organization.guid : null,

      business_name: business ? business.name : null,
      business_guid: business ? business.guid : null,

      service_name: campaign ? campaign.name : null,
      service_guid: campaign ? campaign.guid : null,
    }

    const stepViewData = {
      name: label,
      ...contextData,
    }

    // Send to facebook
    if (business && business.facebook_pixel_id) {
      console.log('facebook',  {business})
      ReactPixel.init(business.facebook_pixel_id, {}, facebookOptions);

      ReactPixel.trackCustom('StepView', stepViewData); // For tracking default events.
      console.log("facebook", stepViewData)

      if (isConversion) {
        ReactPixel.track('Lead', contextData); // For tracking default events.
        console.log("facebook", "Lead")
      }
    }

    // Send to amplitude
    const result = amplitude.getInstance().logEvent('See Step', {
      name,
      isInitialStep,
      firstTouch,
      ...properties,
    });

    if (isInitialStep) {
      this.setState({isInitialStep: false})
    }
  }

  render() {
    const { userDistinctID } = this.state

    return (
      <AnalyticsContext.Provider value={this}>
        {this.props.children}
      </AnalyticsContext.Provider>
    )
  }
}

const withAnalytics = (WrappedComponent) => {

    return (props) => <AnalyticsContext.Consumer>
        {(analytics) => <WrappedComponent {...props} analytics={analytics} />}
    </AnalyticsContext.Consumer>
}

export { AnalyticsProvider, withAnalytics };
