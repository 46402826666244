import React, { useState, useMemo, Component } from 'react'
import {Row, Col, Card, Alert, CardBody, Button, Input, FormGroup, FormFeedback, Label, FormText, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import DropZoneField from 'components/dropzoneField'
import { Spacer } from './components'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import {ElementsConsumer, CardElement, useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement} from '@stripe/react-stripe-js';
import useResponsiveFontSize from "components/useResponsiveFontSize";


export const pruneField = (field_type, props) => {
  /*

  Prunes and formats a field object to applicable fields for rendering.

  Ex. Input
  {
      "name": "Insurance Type",
      "field_type": "text",
      ...
      "text_placeholder": "Insurance Type",
      "text_is_multiline": false,
      "file_extensions": [],
      "email_placeholder": "Email Address",
      "phone_placeholder": "Phone Number",
      ...
  }

  Ex. Output
  {
    "name": "Insurance Type",
    "field_type": "text",
    "placeholder": "Insurance Type",
    "is_multiline": false,

    isVisible: false,
    isValid: false,

    error: null,
    value: null,
  }
  */

  let commonProps = {
    name: props.guid,
    title: props.field_title,
    validation_rules: props.validation_rules,
    visibility_rules: props.visibility_rules,
    value: null,
    error: null,
    field_type: props.field_type,
    validation: {
      required: props.field_is_required,
      valid: props.field_is_valid,
    }
  }

  switch(field_type) {

    case 'contact_first_name_field':
      return {
        placeholder: 'First Name',
        title: 'First Name',
        name: props.guid,
        validation: {
          required: true,
        }
      }
    case 'contact_last_name_field':
      return {
        placeholder: 'Last Name',
        title: 'Last Name',
        name: props.guid,
        validation: {
          required: true,
        }
      }
    case 'contact_phone_field':
      return {
        placeholder: 'Phone Number',
        title: 'Phone Number',
        name: props.guid,
        defaultCountry: props.phone_field_default_country,
        validation: {
          required: true,
          valid: true,
        }
      }
    case 'contact_dob_field':
      return {
        placeholder: 'Date of Birth',
        title: 'Date of Birth',
        name: props.guid,
        validation: {
          required: true,
          valid: true,
        }
      }
    case 'contact_address_field':
      return {
        placeholder: 'Address',
        title: 'Address',
        name: props.guid,
        validation: {
          required: true,
          valid: true,
        }
      }
    case 'contact_email_field':
      return {
        title: 'Email Address',
        placeholder: 'email@address.com',
        name: props.guid,
        validation: {
          required: true,
          valid: true,
        }
      }
    case 'contact_terms_field':
      return {
        text: 'I am freely submitting my personal information and agree to the the <a target="_blank" href="https://mark-test-practice.yourvirtualconsult.com/terms">terms of service.</a>',
        name: props.guid,
        validation: {
          required: true,
        }
      }
    case 'short_text_field':
      return {
        ...commonProps,
        placeholder: props.text_field_placeholder,
        validation: {
          ...commonProps.validation,
          min_chars: props.text_field_min_chars,
          max_chars: props.text_field_max_chars,
        },
      }
    case 'long_text_field':
      return {
        ...commonProps,
        placeholder: props.text_field_placeholder,
        validation: {
          ...commonProps.validation,
          min_chars: props.text_field_min_chars,
          max_chars: props.text_field_max_chars,
        },
      }
    case 'email_field':
      return {
        ...commonProps,
        placeholder: props.text_field_placeholder,
      }
    case 'phone_field':
      return {
        ...commonProps,
        country: props.phone_field_default_country,
        placeholder: props.text_field_placeholder,
      }
    case 'single_choice_field':
      return {
          ...commonProps,
          type: props.single_choice_field_type,
          choices: props.choices,
      }
    case 'multiple_choice_field':
      return {
          ...commonProps,
          type: props.multiple_choice_field_type,
          choices: props.choices,
      }
    case 'number_field':
      return {
        validation: {
          ...commonProps.validation,
          min_value: props.number_field_min_value,
          max_value: props.number_field_max_value,
        },
        ...commonProps,
      }
    case 'boolean_field':
      return {
        ...commonProps,
      }
    case 'image_file_field':
      return {
        ...commonProps,
        choices: props.choices,
        validation: {
          image_file_field_validation: props.image_file_field_validation,
        },
      }
    default:
      return {
        ...commonProps,
      }
  }
}

const FieldTitle = (props) => {
  return <div className="text-muted pb-3">{props.children}</div>
}

export const Field = ({element_type, field, ...props}) => {
  const { valid, invalid, errors, ...otherProps } = props
  const prunedFieldProps = pruneField(element_type, field)

  return (
    <FormGroup>
      {React.createElement(FieldComponents[element_type], {
        valid,
        invalid,
        ...prunedFieldProps,
        ...otherProps,
      })}

      {invalid && errors.length > 0 && <FormFeedback style={{display: 'block'}}>{errors[0]}</FormFeedback>}
    </FormGroup>
  )
}

export const ShortTextField = ({onChange, ...props}) => {
  console.log("RENDERED", props)
  return (
      <div>
        {props.title && <FieldTitle>{props.title}</FieldTitle>}
        <Input
          onChange={e => onChange(e.target.name, e.target.value)}
          placeholder={props.placeholder}
          type="text"
          autoComplete="off"
          {...props}
        />
      </div>
  )
}

export const DateField = ({onChange, ...props}) => {
  console.log("RENDERED", props)
  return (
      <div>
        {props.title && <FieldTitle>{props.title}</FieldTitle>}
        <Input
          onChange={e => onChange(e.target.name, e.target.value)}
          placeholder={props.placeholder}
          type="date"
          autoComplete="off"
          {...props}
        />
      </div>
  )
}

export const AddressField = ({onChange, ...props}) => {
  console.log("RENDERED", props)
  return (
      <div>
        {props.title && <FieldTitle>{props.title}</FieldTitle>}
        <Input
          onChange={e => onChange(e.target.name, e.target.value)}
          placeholder={props.placeholder}
          type="text"
          autoComplete="off"
          {...props}
        />
      </div>
  )
}

export const LongTextField = ({onChange, ...props}) => {
  return (
      <div>
        {props.title && <FieldTitle>{props.title}</FieldTitle>}
        <Input
          onChange={e => onChange(e.target.name, e.target.value)}
          type="textarea"
          autoComplete="off"
          {...props}
        />
      </div>
  )
}

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

export const StripeCreditCardField = ({ name, onChange, value, ...props }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [card, setCard] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [checking, setChecking] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState(null)

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);

    // Use your card Element with other Stripe.js APIs

    setChecking(true)
    setErrorMessage(null)

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
      setErrorMessage(error.message)
      setChecking(false)

    } else {
      console.log('[PaymentMethod]', paymentMethod);
      console.log({paymentMethod})

      setChecking(false)
      onChange(name, paymentMethod.id)
      setPaymentMethod(paymentMethod)
      setShowModal(false)
    }
  };

  return (
    <FormGroup>

      <Modal isOpen={showModal}>
        <ModalHeader toggle={e => setShowModal(!showModal)}>Stripe Credit Card</ModalHeader>

        <ModalBody>

        <Row>
          <Col className="mb-4" xs="12">
            <Card>
              <CardBody>
                <CardNumberElement
                  options={options}

                  onReady={() => {
                    console.log("CardNumberElement [ready]");
                  }}
                  onChange={event => {
                    console.log("CardNumberElement [change]", event);
                  }}
                  onBlur={() => {
                    console.log("CardNumberElement [blur]");
                  }}
                  onFocus={() => {
                    console.log("CardNumberElement [focus]");
                  }}
                />
              </CardBody>
            </Card>
          </Col>

          <Col className="mb-4" xs="12" md="6">
            <Card>
              <CardBody>
                <CardExpiryElement
                  options={options}
                  onReady={() => {
                    console.log("CardNumberElement [ready]");
                  }}
                  onChange={event => {
                    console.log("CardNumberElement [change]", event);
                  }}
                  onBlur={() => {
                    console.log("CardNumberElement [blur]");
                  }}
                  onFocus={() => {
                    console.log("CardNumberElement [focus]");
                  }}
                />
              </CardBody>
            </Card>
          </Col>

          <Col className="mb-4" xs="12" md="6">
            <Card>
              <CardBody>
                <CardCvcElement
                  options={options}
                  onReady={() => {
                    console.log("CardNumberElement [ready]");
                  }}
                  onChange={event => {
                    console.log("CardNumberElement [change]", event);
                  }}
                  onBlur={() => {
                    console.log("CardNumberElement [blur]");
                  }}
                  onFocus={() => {
                    console.log("CardNumberElement [focus]");
                  }}
                />
              </CardBody>
            </Card>
          </Col>

          <Col xs="12" md="6">
            <FormFeedback style={{display: 'block'}}>{errorMessage}</FormFeedback>
          </Col>
        </Row>

        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={e => setShowModal(false)}>Cancel</Button>
          <Button color="primary" disabled={checking} onClick={handleSubmit}>Save</Button>{' '}
        </ModalFooter>
      </Modal>

      <Row>
        <Col>
          <Card className="p-5">
            <div className="text-muted text-center">
              Your information is securely stored on Stripe.
            </div>
            { value && <Alert className="mt-3" color="success">Payment method set</Alert>}
            <Button className="mt-3" onClick={e => setShowModal(true)}>
              { value ? <div>Update Credit Card</div> : <div>Set Credit Card</div> }
            </Button>
          </Card>
        </Col>
      </Row>


    </FormGroup>
  )
}

export const EmailField = ({onChange, ...props}) => {
  return (
      <div>
        {props.title && <FieldTitle>{props.title}</FieldTitle>}
        <Input
          onChange={e => onChange(e.target.name, e.target.value)}
          autoComplete="off"
          {...props}
       />
      </div>
  )
}

export const PhoneField = ({onChange, valid, invalid, ...props }) => {
  return (
      <div class="">
        {props.title && <FieldTitle>{props.title}</FieldTitle>}
        <PhoneInput
          defaultCountry={props.defaultCountry}
          inputClass="w-100 border-muted"
          countrySelectProps={{
            tabIndex: -1,
          }}
          onChange={newValue => onChange(props.name, newValue)}
          {...props}
        />
      </div>
  )
}

export const SingleChoiceField = ({name, onBlur, display_name, value, onChange, choices=[], type, ...props}) => {

  if (type=='dropdown') {
    return (
      <div>
        {props.title && <FieldTitle>{props.title}</FieldTitle>}
        <Input type="select" value={value} onChange={e => onChange(name, e.target.value, {updateFocus: true, autoProgress: true, updateResponse: true})} {...props}>
          <option value=''>{props.title}</option>
          {choices.map((choice, index) => (
            <option key={choice.guid} value={choice.guid}>{choice.name}</option>
          ))}
        </Input>
      </div>
    )

  } else if (type=='radio') {
    return (
      <div>
        {props.title && <FieldTitle>{props.title}</FieldTitle>}

        <FormGroup>
        {choices.map((choice, index) => {
          const isChecked = value == choice.guid
          return (
            <FormGroup check>
              <Input
                name={name}
                value={choice.guid}
                type="radio"
                checked={isChecked}
                onClick={e => onChange(name, choice.guid, {updateFocus: true, autoProgress: true, updateResponse: true})}
              />
              {' '}
              <Label
                onClick={e => onChange(name, choice.guid, {updateFocus: true, autoProgress: true, updateResponse: true})}
                check>
                {choice.name}
              </Label>
            </FormGroup>
          )

        })}
        </FormGroup>
      </div>
    )

  } else if (type=='button') {

    return (
      <div>
        {props.title && <FieldTitle>{props.title}</FieldTitle>}
        {choices.map((choice, index) => {
          const isChecked = value == choice.guid
          return (
            <Button
              className={"btn btn-primary " + (index > 0 ? 'mt-3 ' : '')+ (isChecked ? 'bg-primary' : 'bg-secondary')}
              onClick={e => onChange(name, choice.guid, {updateFocus: true, autoProgress: true, updateResponse: true})}
              key={index}
              block
            >{choice.name}</Button>
          )
        })}
      </div>
    )
  }
}

export const CheckboxField = ({ name, onChange, text, value, ...props}) => {
  return (
    <FormGroup check className="mb-3">
      <Label for={name} check>
        <Input
          id={name}
          type="checkbox"
          checked={value}
          onChange={e => onChange(name, !value)}
          {...props}
        />{' '}
        <div
          dangerouslySetInnerHTML={{__html: text}}
        />
      </Label>
    </FormGroup>
  )
}

export class ImageField extends Component {
  constructor(props) {
    super(props)
    this.state = {...props.value}
  }

  handleChoiceChange = (choice, choiceValue) => {
    const { name, onChange } = this.props

    this.setState(
      prevState => ({...prevState, [choice.guid]: choiceValue}),
      () => {
        onChange(name, this.state)
      }
    )
  }

  render() {
    const { choices=[], title } = this.props
    return (

      <div className="w-100">
        {title && <FieldTitle>{title}</FieldTitle>}
      {choices.map((choice, index) => {
        return (
          <div key={choice.guid}>
            <DropZoneField
              value={this.state[choice.guid]}
              onChange={v => this.handleChoiceChange(choice, v)}
              label={choice.name}
              exampleImage={choice.image}
              type="text"

            />
            {index < choices.length-1 && <Spacer />}
          </div>
        )
      })}
      </div>
    )
  }
}


export class MultipleChoiceField extends Component {

  toggle = (choice) => {
    let {name, onChange, value } = this.props;
    let inputValue = [...value];

    if (inputValue.includes(choice.guid)) {
      inputValue.splice(inputValue.indexOf(choice.guid), 1);
    } else {
      inputValue.push(choice.guid);
    }

    onChange(name, inputValue)
  }


  render() {
    const {name, onBlur, display_name, value, onChange, choices=[], type, ...props} = this.props

    if (type == 'checkbox') {
      return (
        <div>
          {props.title && <FieldTitle>{props.title}</FieldTitle>}

          <FormGroup>
          {choices.map((choice, index) => {
            const isChecked = value.includes(choice.guid)
            return (
              <FormGroup check>
                <Input
                  name={name}
                  value={choice.guid}
                  type="checkbox"
                  checked={isChecked}
                  onClick={() => this.toggle(choice)}
                />
                {' '}
                <Label
                  onClick={() => this.toggle(choice)}
                  check>
                  {choice.name}
                </Label>
              </FormGroup>
            )

          })}
          </FormGroup>
        </div>
      )
    } else {
      return (
        <div>
          {props.title && <FieldTitle>{props.title}</FieldTitle>}
          {choices.map((choice, index) => {
            const isChecked = value.includes(choice.guid)
            return (
              <Button
                className={"btn btn-primary " + (index > 0 ? 'mt-3 ' : '')+ (isChecked ? 'bg-primary' : 'bg-secondary')}
                onClick={() => this.toggle(choice)}
                key={index}
                block
              >{choice.name}</Button>
            )
          })}
        </div>
      )
    }

  }
}



export const FieldComponents = {
    short_text_field: ShortTextField,
    long_text_field: LongTextField,
    email_field: EmailField,
    phone_field: PhoneField,
    address_field: AddressField,
    single_choice_field: SingleChoiceField,
    multiple_choice_field: MultipleChoiceField,

    phone_number_field: PhoneField,
    image_file_field: ImageField,
    stripe_payment_method_field: StripeCreditCardField,
    checkbox_field: CheckboxField,

    contact_first_name_field: ShortTextField,
    contact_last_name_field: ShortTextField,
    contact_dob_field: DateField,
    contact_address_field: AddressField,
    contact_phone_field: PhoneField,
    contact_email_field: EmailField,
    contact_terms_field: CheckboxField,
}
