import React, {Component} from 'react';
import Form from 'components/form/Form'

export default class FormPage extends Component {
  render() {
    const { history, match } = this.props
    const { form_version_guid, form_response_guid } = match.params

    return (
      <Form
        form_version_guid={form_version_guid}
        response_guid={form_response_guid}
        onResponseSubmit={(response) => {
          history.push({
            pathname: '/success',
            state: {
              business: response.business,
            }
          })
        }}
       />
    )
  }
}
