import React, { Component } from 'react';
import Page from 'routes/Page'
import { connect } from 'react-redux'

import GoogleReviews from 'components/GoogleReviews/GoogleReviews'
import FormattedPhoneNumber from 'components/FormattedPhoneNumber'
import { Link } from 'react-router-dom'
import {Container, Row, Col } from 'reactstrap'
import FacebookLogo from "scss/icons/facebook.png"
import TwitterLogo from "scss/icons/twitter.png"
import InstagramLogo from "scss/icons/instagram.png"



class Success extends Component {

  constructor(props) {
    super(props)
    const { location } = this.props
    let business = null

    if (location && location.state) {
      business = location.state.business
    }

    this.state = {
      business,
      place: null,
    }
  }

  componentDidMount() {

    const { business } = this.state
    if (business) {

      const { google_business_place_id } = business

      if (google_business_place_id) {
        this.getGoogleBusiness(google_business_place_id)
      }
    }
  }

  getGoogleMaps() {
    // If we haven't already defined the promise, define it
    if (!this.googleMapsPromise) {
      this.googleMapsPromise = new Promise((resolve) => {
        // Add a global handler for when the API finishes loading
        window.resolveGoogleMapsPromise = () => {

          let google = window.google;
          // Resolve the promise
          resolve(google);

          // Tidy up
          delete window.resolveGoogleMapsPromise;
        };

        // Load the Google Maps API
        const script = document.createElement("script");
        const API = 'AIzaSyBt6Eb5jAq42RnaZcfysyXi8HzKL7AiK5k';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&libraries=places&callback=resolveGoogleMapsPromise`;
        script.async = true;
        document.body.appendChild(script);
      });
    }

    // Return a promise for the Google Maps API
    return this.googleMapsPromise;
  }

  getGoogleBusiness = id => {
    this.getGoogleMaps()
      .then((google) => {
        console.log("GOOGLE", {google})

        const service = new google.maps.places.PlacesService(document.createElement('div'))

        var request = {
          placeId: id,
          fields: ['rating', 'reviews', 'opening_hours', 'adr_address']
        };

        service.getDetails(request, (place, status) => {
          console.log("google", place)
          this.setState({place})
        })

      })
  }

  render() {
    const { business, place } = this.state
    const { website } = business
    const place_id = business.google_business_place_id
    const formattedWebsite = website && !website.includes('://') ? `//${website}` : website

    return (
      <Container className="thank-you-page main brown h-100">
        <Row className="h-100">
          <Col className="d-flex justify-content-center text-center align-items-center" xs={12}>

            <div>

              {business.logo && (
                <div className="mt-4 mb-4 w-100">
                  { website ? (
                    <a href={formattedWebsite} target="_blank">
                      <img src={business.logo} width="120" />
                    </a>
                  ) : (
                    <img src={business.logo} width="120" />
                  )}
                </div>
              )}

              <h1 className="">Thank You!</h1>

              <div className="mt-5">
                <p>Your information has been transmitted securely, and {business.name} will get back to you shortly!</p>
              </div>

              {place && <div>
                <hr className="my-4" />
                <div>
                    <p dangerouslySetInnerHTML={{__html: place.adr_address}} className="text-center py-2 address" />
                </div>

                <div>
                    {place.opening_hours.weekday_text.map(weekday_text => (
                        <div className="mt-3 text-center">{weekday_text}</div>
                    ))}
                </div>
              </div>}

              {business.phone_number && (

                <div className="pb-4">
                  <hr className="my-4" />
                  <p>Feel free to call us if you have any questions.</p>
                  <p><FormattedPhoneNumber>{business.phone_number}</FormattedPhoneNumber></p>
                </div>
              )}


            </div>
          </Col>
        </Row>
      </Container>
    )
  }

}

export default Success
