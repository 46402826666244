import React, { Component } from 'react';
import {getConfig, getSubdomain} from 'config'
import axios from "axios"
import Parser from 'html-react-parser';
import {Container, Row, Col } from 'reactstrap'

let subdomain = getSubdomain();
const { apiEndpoint } = getConfig();

const backend = axios.create({
  baseURL: `${apiEndpoint}`
})

class Success extends Component {

  constructor(props) {
    super(props)

    this.state = {
      organization: null
    }

    this.getOrganization = this.getOrganization.bind(this)
  }

  getOrganization() {
    const result = backend.get(`/organizations/subdomain/${subdomain}/`)
      .then(response => {
        const { data } = response
        const organization = data
        this.setState({ organization })
        console.log({organization})
      })
      .catch(error => {
        console.log({error})
        alert('error')
      })
  }

  componentDidMount() {
    this.getOrganization()
  }

  render() {
    const { organization } = this.state

    return (
      <Container className="thank-you-page main brown pt-5 h-100">
        <Row className="h-100">
          <Col className="" xs={12}>
            <div>
              <p class="c25 c17"><span class="c21">Terms &amp; Conditions</span></p>
              <p class="c7"><span class="c9">1. Acceptance of the Terms of Use</span></p>
              <p class="c5"><span class="c2">Welcome to Your Virtual Consult, a </span><span class="c14">SaaS platform operated by </span><span class="c2">Your Virtual Consult, LLC (&quot;</span><span class="c8">Company</span><span class="c2">&quot;,
                  &quot;</span><span class="c8">we</span><span class="c2">&quot; or &quot;</span><span class="c8">us</span><span class="c2">&quot;), </span><span class="c14">that allows dentists to promote, perform, and manage virtual consults with existing and
                  prospective patients. </span><span class="c2">The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, these &quot;</span><span class="c8">Terms of Use</span><span
                  class="c2">&quot;), govern your access to and use of Your Virtual Consult or www.yourvirtualconsult.com, including any content, functionality and services offered on or through Your Virtual Consult www.yourvirtualconsult.com (the
                  &quot;</span><span class="c8">Platform</span><span class="c2">&quot;), whether as a guest, a registered user, a contractor, or a reseller.</span></p>
              <p class="c5"><span class="c2">Please read the Terms of Use carefully before you start to use the Platform. </span><span class="c8">By using the Platform or by clicking to accept or agree to the Terms of Use when this option is made available to
                  you, you accept and agree to be bound and abide by these Terms of Use.</span><span class="c2">&nbsp;If you do not agree to these Terms of Use, you must not access or use the Platform.</span></p>
              <p class="c5"><span class="c2">This Platform is offered and available to users who are over 18 years of age or older and reside in the United States or any of its territories or possessions. By using this Platform, you represent and warrant that
                  you are of legal age to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Platform.</span></p>
              <p class="c5"><span class="c8">You may not use this Platform on behalf of any person under the age of 13</span><span class="c2">. </span><span class="c8">You agree that you will not submit any information pertaining to any person under the age of
                  13.</span></p>
              <p class="c3"><span class="c2">If you are registering an account or using the Platform on behalf of an individual or entity other than yourself, you represent that you are authorized by such individual or entity to accept these Terms of Use on such
                  individual or entity&#39;s behalf. Whether you are an individual seeking to obtain a virtual consultation, an individual or representative of an entity seeking to provide a virtual consultation, or a contractor authorized to use the Platform,
                  these terms of use apply to you in your respective context. If you are using the Platform on behalf of an individual or entity other than yourself, then all references to &quot;you&quot; in these Terms of Use will refer to such other individual
                  for whom you have authorization to enter into these Terms of Use on their behalf, and you in your capacity as the authorized party of such individual. If you do not qualify under these terms, do not use the Platform or Services. Registration
                  for Services or use of the Platform is void where prohibited by applicable law, and your right to access the Platform is revoked in those jurisdictions.</span></p>
              <p class="c7"><span class="c9">2. About the Platform</span></p>
              <p class="c5"><span class="c2">Everything offered on the Platform is referred to in these Terms of Use collectively as the &quot;</span><span class="c8">Services</span><span class="c2">.&quot; Some of what is on the Platform is viewable without
                  registering, but to actively participate or store your information, you must register as a member and authorize the use and disclosure of your personal and health information for purposes of allowing us to provide the Services.</span></p>
              <p class="c3"><span class="c2">You acknowledge that although some of the content, text, data, graphics, images, information, suggestions, and other materials (collectively, &quot;Information&quot;) that is provided to you on the Platform (including
                  information provided in direct response to your questions or postings) may be provided by individuals in the medical profession, the provision of such Information does not create a medical professional/patient relationship, and does not
                  constitute an opinion, medical advice, or diagnosis or treatment of any particular condition.</span></p>
              <p class="c7"><span class="c9">3. No Medical Advice Is Provided</span></p>
              <p class="c5"><span class="c2">The Information that you obtain or receive from the Platform, and its employees, contractors, partners, sponsors, advertisers, licensors or otherwise on the Platform is for informational and scheduling purposes only.
                  All medically related information comes from independent health care professionals or organizations. If you access any Information on the Platform, that Information is (i) provided by independent health care professionals and organizations,
                  (ii) for informational purposes only, and (iii) does not establish any patient/doctor relationship.</span></p>
              <p class="c5"><span class="c2">THE INFORMATION PROVIDED ON THE PLATFORM AND IN ANY OTHER COMMUNICATIONS FROM OR PROVIDED THROUGH THE PLATFORM IS NOT INTENDED AS A SUBSTITUTE FOR, NOR DOES IT REPLACE, PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR
                  TREATMENT. DO NOT DISREGARD, AVOID OR DELAY OBTAINING MEDICAL ADVICE FROM A QUALIFIED HEALTH CARE PROFESSIONAL BECAUSE OF INFORMATION YOU MAY HAVE READ OR SEEN ON THE PLATFORM. </span><span class="c8">DO NOT USE THE PLATFORM FOR EMERGENCY
                  MEDICAL NEEDS. IF YOU EXPERIENCE A MEDICAL EMERGENCY, CALL 911 IMMEDIATELY</span><span class="c2">. YOUR USE OF INFORMATION PROVIDED ON THE PLATFORM IS SOLELY AT YOUR OWN RISK. NOTHING STATED OR POSTED ON THE SITE OR AVAILABLE THROUGH ANY
                  SERVICES IS INTENDED TO BE, AND MUST NOT BE TAKEN TO BE, THE PRACTICE OF MEDICINE OR THE PROVISION OF MEDICAL CARE.</span></p>
              <p class="c3"><span class="c2">We do not recommend or endorse any specific tests, doctors, dentists, health care providers, procedures, opinions, or other information that may appear on the Platform. If you rely on any of the Information provided
                  by or on the Platform, you do so at your own risk.</span></p>
              <p class="c7"><span class="c9">4. No Doctor-Patient Relationship</span></p>
              <p class="c5"><span class="c2">NO LICENSED MEDICAL PROFESSIONAL/PATIENT RELATIONSHIP IS NECESSARILY CREATED BY USING INFORMATION PROVIDED BY OR THROUGH THE USE OF THE PLATFORM OR THROUGH ANY OTHER COMMUNICATIONS FROM COMPANY, INCLUDING, BUT NOT
                  LIMITED TO, THE SCHEDULING OF AN APPOINTMENT WITH A PROVIDER AND/OR MEDICAL/HEALTHCARE RELATED MATERIALS ON THE PLATFORM, LINKS TO OTHER SITES OR ANY INFORMATION OR ASSISTANCE PROVIDED ON OR THROUGH THE PLATFORM TO HELP YOU SCHEDULE AN
                  APPOINTMENT WITH A MEDICAL PROFESSIONAL OR SPECIALIST IN ANY FIELD. EMERGING TRENDS IN THE HEALTH CARE INDUSTRY MAY PERMIT DOCTORS AND PATIENTS TO FORM A DOCTOR-PATIENT RELATIONSHIP THROUGH THEIR CONDUCT ON THE PLATFORM, INDEPENDENT OF ANY
                  KNOWLEDGE, ACTION, OR CONSENT OF THE COMPANY. ANY SUCH RELATIONSHIP WILL BE BY THE CHOICE AND CONDUCT OF THE PROVIDER AND PROSPECTIVE PATIENT, AND COMPANY IS NOT RESPONSIBLE FOR THE OUTCOMES OF THAT POTENTIAL RELATIONSHIP. COMPANY INTENDS AND
                  EXPECTS, AND USERS OF THE PLATFORM AGREE, THAT IN-OFFICE CONSULTATIONS WILL FOLLOW THE USE OF THE PLATFORM TO ESTABLISH A DOCTOR-PATIENT RELATIONSHIP AND DETERMINE WHETHER A COURSE OF CARE IS APPROPRIATE.</span></p>
              <p class="c5"><span class="c2">COMPANY MAKES NO GUARANTEES, REPRESENTATIONS OR WARRANTIES, WHETHER EXPRESSED OR IMPLIED, WITH RESPECT TO THE PROFESSIONAL QUALIFICATIONS, EXPERTISE, QUALITY OF WORK OF ANY MEDICAL PROFESSIONAL OR SPECIALIST OR OTHER
                  INFORMATION HEREIN. FURTHERMORE, COMPANY DOES NOT IN ANY WAY ENDORSE ANY INDIVIDUAL DESCRIBED HEREIN. IN NO EVENT WILL COMPANY BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON SUCH INFORMATION. COMPANY
                  WILL NOT BE LIABLE FOR THE ACTIONS OR INACTIONS OF ANY MEDICAL PROFESSIONAL OR SPECIALIST, WHETHER LISTED ON THIS SITE OR NOT.</span></p>
              <p class="c5"><span class="c2">Company has no control over, and cannot guarantee, the advice and recommendations provided in virtual consults at any time. Company will not be liable for dental work resulting from consults or any injury resulting
                  therefrom, or for any other injury resulting from the use of the Platform or Services whatsoever.</span></p>
              <p class="c3"><span class="c2">Company strongly advises, and you agree that you will perform your own investigation prior to selecting a health care professional by making confirming telephone calls to the appropriate licensing authorities to
                  verify listed credentials and education, and to further verify information about a particular health care professional, doctor, dentist or other health care provider (collectively, &quot;Provider&quot;) by confirming with the Provider&#39;s
                  office, your current Provider, the medical association relative to the Provider&#39;s specialty and your state medical board.</span></p>
              <p class="c7"><span class="c9">5. Important Information About Provider Relationships and Lists</span></p>
              <p class="c5"><span class="c2">In connection with using the Platform and the Services to locate and schedule appointments with medical or health care professionals, you understand that:</span></p>
              <ul class="c18 lst-kix_list_1-0 start">
                <li class="c6 li-bullet-0"><span class="c2">YOU ARE SOLELY RESPONSIBLE FOR CHOOSING YOUR OWN PROVIDER.</span></li>
                <li class="c6 li-bullet-0"><span class="c2">Company only features Providers who have paid a fee and signed a contractual agreement with Company.</span></li>
                <li class="c6 li-bullet-0"><span class="c2">Company will provide you with lists of Providers who have stated that they are suitable to deliver the health care service that you are seeking based solely on information that you provide to Company
                    and based on your user settings (such as insurance information, proximity to your geographical location, and specialty of the Provider). The lists may be based on other criteria (including, for example, Provider availability, past selections
                    by and/or ratings of Providers by you or by other Platform users, and past experience of Platform users with Providers). However, Company does not recommend or endorse any Provider, does not make any representations or warranties with respect
                    to these Providers or the quality of the healthcare services they may provide, and does not receive any additional fees from Provider for featuring them through the Services.</span></li>
                <li class="c6 li-bullet-0"><span class="c2">Company DOES NOT investigate any Provider to determine whether they hold active medical licenses or certifications necessary to practice any specialty or services. Additionally, while Company may
                    receive information regarding any Provider&#39;s medical license or certification status or information regarding inappropriate or unprofessional conduct, Company does not have any obligation to investigate any such information received and
                    does not have any obligation to update any listings based on any such information.</span></li>
                <li class="c6 li-bullet-0"><span class="c2">If you elect to enter information into a medical history form (&quot;Medical History Form&quot;) on behalf of yourself or a third party from whom you have received authorization to provide such
                    information, on your request you authorize Company to provide such information to the Provider you specify. You also acknowledge and agree that such information will be reviewed and approved by you or someone authorized on your behalf at the
                    time of your appointment to ensure its accuracy. Further, you acknowledge that Company may use the data or information you provide on a Medical History Form in accordance with these Terms of Use.</span></li>
              </ul>
              <p class="c7"><span class="c9">6. Editorial Control</span></p>
              <p class="c5"><span class="c2">Company may, but has no obligation to, have Information posted on the Platform reviewed by the Company personnel. The timeliness and accuracy of any or all of the Information is not guaranteed. Neither Company, the
                  authors, Company personnel, nor any other party who may have been involved in the preparation or publication of any Information assures you that the Information contained on or through the Platform is accurate or complete. Furthermore, Company,
                  the authors, Company personnel or any other party who may have been involved in the preparation or publication of any Information is/are not responsible for any errors or omissions or for the results obtained from the use of any
                  Information.</span></p>
              <p class="c5"><span class="c2">Company strongly encourages you to and you agree to independently confirm the Information contained on or through the Platform with other sources and to seek the advice of a qualified physician or other health care
                  provider.</span></p>
              <p class="c5"><span class="c2">INFORMATION ON PROVIDERS: Provider and practice information found on or through the Platform is intended for general reference purposes only. Provider information found on or through the Platform is self-reported by
                  the Provider and/or the Provider&#39;s staff and is not confirmed by Company. This information often changes frequently and may become out of date or inaccurate. Neither the Platform nor Company provides any advice or qualification
                  certification for or about any Provider. You must independently verify any information to ensure it is accurate and up to date.</span></p>
              <p class="c3"><span class="c2">PROCEDURE/PRODUCT INFORMATION AND SERVICES: The procedures, products, services and devices discussed and/or advertised on the Platform are not applicable to all individuals, patients, or all clinical situations.
                  Company makes no claims as to the effectiveness of any such procedures, products, services or devices. Any products and/or services represented on or through the Platform by advertisers, sponsors, other Platform users, either paid or unpaid, or
                  by Providers or other sources are included for your awareness and do not imply that they are appropriate for any particular individual or prediction of effectiveness, outcome or success.</span></p>
              <p class="c7"><span class="c9">7. Personal Information and Privacy</span></p>
              <p class="c5"><span class="c2">In order to register, you must provide certain personal and medical information about yourself. You agree that the submittal of this personal and medical information is voluntarily submitted by you. Additionally, you
                  hereby authorize Company, its employees, agents and others operating on its behalf, to use and/or disclose your personal and medical or other health care related information, including, but not limited to: (1) your name, address, social
                  security number, contact information; (2) your medical information or other health care related information; (3) your medical, health care, and/or social history; (3) your current medical needs or issues; and (4) any other information that is
                  either requested by Company or volunteered by you (collectively with the items contained in (1) through (4), &quot;Personal Information&quot;), in accordance with these Terms of Use.</span></p>
              <p class="c5"><span class="c2">If you choose, or you are provided with a user name, password or any other piece of information as part of Company&#39;s security procedures, you must treat such information as confidential and you must not disclose
                  it to any third party. You agree to immediately notify Company of any unauthorized use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You
                  should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</span></p>
              <p class="c5"><span class="c2">You acknowledge that transmission to and from this Platform are not confidential and your User Contributions and Personal Information or any other content you submit to the Platform or through the Platform may be read
                  or intercepted by others. You acknowledge that by submitting any User Contributions, Personal Information, or any other content to Company, to the Platform, or through the Platform, no confidential, fiduciary, contractually implied or other
                  relationship is created between you and Company other than pursuant to these Terms of Use.</span></p>
              <p class="c5"><span class="c2">Company has the right to disable any user identification code or password, whether chosen by you or provided by Company, at any time, in Company&#39;s sole discretion for any or no reason, including, if in
                  Company&#39;s opinion, you have failed to comply with any provision of these Terms of Use.</span></p>
              <p class="c5"><span class="c2">By providing Personal Information, you fully understand and unambiguously agree to the transfer of such Personal Information to, and the collection and processing of such Personal Information in, the United States.
                  Your Personal Information may be stored and processed on Company computers in the United States. If Company holds and transmits your Personal Information, Company will use commercially reasonable efforts to hold and transmit Personal
                  Information in a safe, confidential and secure environment. If you do not agree to your Personal Information being transferred or used in this manner, then you must not submit Personal Information to Company or use the Platform or the
                  Services.</span></p>
              <p class="c3"><span class="c2">You acknowledge and agree that Company may collect and disclose certain personally identifiable information, including but not limited to Personal Information, if required to do so by law or in the good faith belief
                  that such preservations or disclosure is reasonably necessary to: (a) comply with legal process or other governmental inquiry; (b) enforce the Terms of Use; (c) take action regarding suspected illegal activities; (d) protect Company&#39;s
                  rights, reputation, and property, or that of Company&#39;s affiliates, or the public. YOU WAIVE. HOLD HARMLESS, AND AGREE TO DEFEND COMPANY AND ITS AFFILIATES, SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, MEMBERS, PARTNERS,
                  REPRESENTATIVES, OR SHAREHOLDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY COMPANY DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER COMPANY OR LAW ENFORCEMENT
                  AUTHORITIES.</span></p>
              <p class="c7"><span class="c9">8. Your Responsibilities:</span></p>
              <p class="c5"><span class="c2">You will be solely responsible for the payment of any fees required by any Provider with which you schedule an appointment, including, but not limited to, fees for a missed appointment. If you are a Provider, you are
                  responsible for all fees required under your services agreement or subscription granting you access to using the Platform and Services.</span></p>
              <p class="c3"><span class="c2">You are solely responsible for all use of the Platform, including any use of the Platform with your username and password by others. You agree to </span><span class="c4">ensure that your use of the Platform and
                  Services shall comply with all applicable international, national and local laws and regulations. </span></p>
              <p class="c3"><span class="c13">Use of the Platform and Services requires one or more compatible devices, Internet access (fees may apply), and certain software (fees may apply), and may require obtaining updates or upgrades from time to time.
                  Because use of the Platform and Services involves hardware, software, and Internet access, your ability to use such Platform and Services may be affected by the performance of these factors. High speed Internet access is recommended. You
                  acknowledge and agree that such system requirements, which may be changed from time to time, are your responsibility.</span></p>
              <p class="c3"><span class="c4">You will be required to provide information about yourself in order to register for and/or use the Platform and Services. You agree that any such information shall be accurate. You may also be asked to choose a user
                  name and password. If so, you are entirely responsible for maintaining the security of your user name and password and agree not to disclose such to any third party. You agree that you are solely responsible for the content sent by you or
                  displayed or uploaded by you in using the Platform and Services (&quot;Content&quot;). You agree that you will not use the Platform and Services to send unsolicited commercial e-mail outside your company or organization in violation of
                  applicable law. You further agree not to use the Platform and Services to communicate any message or material that is harassing, libelous, threatening, obscene, indecent, would violate the intellectual property rights of any party or is
                  otherwise unlawful, that would give rise to civil liability, or that constitutes or encourages conduct that could constitute a criminal offense, under any applicable law or regulation. You further agree not to provide material support or
                  resources (or to conceal or disguise the nature, location, source, or ownership of material support or resources) to any organization(s) designated by the United States government as a foreign terrorist organization pursuant to section 219 of
                  the Immigration and Nationality Act. You further agree not to upload or transmit any software, Content or code that does or is intended to harm, disable, destroy or adversely affect performance of the Platform and Services in any way or which
                  does or is intended to harm or extract information or data from other hardware, software or networks of More Active or other users of the Platform and Services. Recognizing the global nature of the Internet, you also agree to comply with
                  applicable local rules or codes of conduct (including codes imposed by your employer) regarding online behavior and acceptable content and the transmission of technical data exported from the United States or the country in which you reside.
                  Company reserves the right to investigate and take appropriate action against anyone who, in Company&rsquo;s sole discretion, is suspected of violating this provision, including without limitation, reporting you to law enforcement authorities.
                  Use of the Platform and Services is void where prohibited. Although Company is not responsible for any Content in violation of this provision, Company may delete any such Content of which Company becomes aware, at any time without notice to
                  you. </span></p>
              <p class="c3"><span class="c4">It is possible that other users of the Platform and Services (&quot;Users&quot;) may violate one or more of the above prohibitions. Company assumes no responsibility or liability for such violation. If you become
                  aware of any violation of these Terms of Use in connection with use of the Platform and Services by any person, please contact Company. More Active may investigate any complaints and violations that come to its attention and may take any action
                  that it believes appropriate, including, but not limited to issuing warnings, removing the content or terminating accounts and/or User profiles. However, because situations and interpretations vary, Company also reserves the right not to take
                  any action. Under no circumstances will Company be liable in any way for any data or other content viewed while using the Platform and Services, including, but not limited to, any errors or omissions in any such data or content, or any loss or
                  damage of any kind incurred as a result of the use of, access to, or denial of access to any data or content. If at any time You are not happy with the Platform and Services, Your sole remedy is to cease using the Platform and Services.</span>
              </p>
              <p class="c7"><span class="c9">9. Changes to the Terms of Use</span></p>
              <p class="c5"><span class="c2">We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them and apply to all access to and use of the Platform thereafter.</span></p>
              <p class="c3"><span class="c2">Your continued use of the Platform following the posting of revised Terms of Use means that you accept and agree to the changes. You agree to check this page each time you access this Platform so you are aware of any
                  changes, as they are binding on you.</span></p>
              <p class="c7"><span class="c9">10. Accessing the Platform and Account Security</span></p>
              <p class="c5"><span class="c2">We reserve the right to withdraw or amend this Platform, and any service or material we provide on the Platform, in our sole discretion without notice. We will not be liable if, for any reason, all or any part of the
                  Platform is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Platform, or the entire Platform, to users, including registered users.</span></p>
              <p class="c5"><span class="c2">You are responsible for:</span></p>
              <p class="c5"><span class="c2">Making all arrangements necessary for you to have access to the Platform.</span></p>
              <p class="c5"><span class="c2">Ensuring that all persons who access the Platform through your internet connection are aware of these Terms of Use and comply with them.</span></p>
              <p class="c3"><span class="c2">To access the Platform or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Platform that all the information you
                  provide on the Platform is correct, current and complete.</span></p>
              <p class="c7"><span class="c9">11. Intellectual Property Rights</span></p>
              <p class="c5"><span class="c2">The Platform and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof),
                  are owned by the Company, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.</span></p>
              <p class="c5"><span class="c2">These Terms of Use permit you to use the Platform for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish,
                  download, store or transmit any of the material on our Platform, except as follows:</span></p>
              <p class="c5"><span class="c2">Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</span></p>
              <p class="c5"><span class="c2">You may store files that are automatically cached by your Web browser for display enhancement purposes.</span></p>
              <p class="c5"><span class="c2">You may print or download one copy of a reasonable number of pages of the Platform for your own personal, non-commercial use and not for further reproduction, publication or distribution.</span></p>
              <p class="c5"><span class="c2">If Company provides desktop, mobile or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound
                  by our end user license agreement for such applications.</span></p>
              <p class="c5"><span class="c2">If we provide </span><span class="c2 c11">social media features</span><span class="c2">&nbsp;with certain content, you make take such actions as are enabled by such features.</span></p>
              <p class="c5"><span class="c2">You must not:</span></p>
              <p class="c5"><span class="c2">Modify copies of any materials from this site.</span></p>
              <p class="c5"><span class="c2">Use any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text.</span></p>
              <p class="c5"><span class="c2">Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from this site.</span></p>
              <p class="c5"><span class="c2">You must not access or use for any commercial purposes any part of the Platform or any services or materials available through the Platform.</span></p>
              <p class="c3"><span class="c4 c17">If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Platform in breach of the Terms of Use, your right to use the Platform will cease immediately and
                  you must, at our option, return or destroy any copies of the materials you have made. No right, title or interest in or to the Platform or any content on the site is transferred to you. All rights not expressly granted are reserved by the
                  Company. Any use of the Platform not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other laws.</span></p>
              <p class="c3"><span class="c4">You acknowledge and agree that the online SaaS (Software as a Service) platform within the Platform is being licensed with the limitations included herein to you, not sold. The Platform constitutes valuable and
                  confidential trade secrets of Company and is proprietary to Company. All applicable copyrights, trade secrets, trademarks, patents and other intellectual and proprietary rights in and to the Platform and all copies thereof are and shall remain
                  the property of Provider. All aspects of the Platform, including without limitation, all designs, engineering details, algorithms, programs, methods of processing, specific design and structure of individual programs and their interaction, and
                  the programming techniques employed therein are and shall remain the sole and exclusive property of Company. IT IS EXPRESSLY UNDERSTOOD THAT NO TITLE TO OR INTELLECTUAL PROPERTY OWNERSHIP OF THE PLATFORM, OR ANY PART THEREOF, IS HEREBY
                  TRANSFERRED TO YOU.</span></p>
              <p class="c3"><span class="c4">Except as otherwise set forth herein to the contrary, all intellectual property rights in and to Company&rsquo;s content, including the Platform, along with Company&rsquo;s improvements to those materials and any
                  derivative works of the Platform, are owned by Company, and will remain the sole and exclusive property of Company. Company, pursuant to all of its intellectual property rights in the Platform, hereby grants to you the non-exclusive right and
                  license to use the Platform in accordance with the terms stated herein and, in the case of providers, any related service agreement or subscription. Such right and license shall be limited to the period of use of the Platform, or, in the case
                  of providers, the term of your services agreement or subscription.</span></p>
              <p class="c3"><span class="c4">You agree to cooperate with Company, both during and after the term of your permitted use of the Platform and Serivces, in the procurement and maintenance of Company&#39;s rights to intellectual property created
                  hereunder and to execute, when requested, any other documents deemed necessary or appropriate by Company to facilitate the continued availability and use of the Platform and Services.Company retains ownership of all proprietary rights in the
                  Platform and in all trade names, trademarks, service marks, logos, and domain names (&quot;Marks&quot;) associated or displayed on the Platform. You may not frame or utilize framing techniques to enclose any Marks, or other proprietary
                  information (including images, text, page layout, or form) without express written consent. You may not use any meta tags or any other &quot;hidden text&quot; utilizing Marks without express written consent. You acquire only the limited right
                  to use the Platform, subject to the terms and conditions herein, and do not acquire any rights of ownership whatsoever. All right, title, and interest in and to the Platform, including without limitation, all intellectual property rights
                  therein, will at all times remain with Company. All intellectual property rights in any work arising from, or created, developed or produced by Company (whether jointly or in connection or concert with others) under or in the course of
                  providing the Platform shall immediately upon creation or performance vest absolutely in Company and shall remain the sole and exclusive property of Company and you shall acquire no right, title or interest therein. You will not cause or permit
                  the reverse engineering, disassembly, de-compilation, or otherwise attempt to derive source or object code of the Platform. </span></p>
              <p class="c3"><span class="c4">You agree that the Platform and the authorship, systems, ideas, methods of operation, documentation and other information contained in the Platform, are proprietary intellectual properties and/or the valuable trade
                  secrets of the Company or its suppliers and/or licensors and are protected by civil and criminal law, and by the law of copyright, trade secret, trademark and patent of the United States, other countries and international treaties. The Company
                  and/or its suppliers own and retain all right, title, and interest in and to the Platform, including without limitations any error corrections, enhancements or other modifications to the Platform, whether made by the Company or any third party,
                  and all copyrights, patents, trade secret rights, trademarks, and other intellectual property rights therein. Your possession, installation or use of the Platform does not transfer to you any title to the intellectual property in the Platform,
                  and you will not acquire any rights to the Platform except as expressly set forth in these Terms of Use. Except as stated herein, these Terms of Use do not grant you any intellectual property rights in the Platform and you acknowledge that
                  these Terms of Use only provides you with a right of limited use of the Platform. Company reserves all rights not expressly granted to you in these Terms of Use. You agree not to modify, adapt, translate, reverse engineer, decompile,
                  disassemble or otherwise attempt to discover the source code of the Platform in any way.</span></p>
              <p class="c7"><span class="c9">12. Trademarks</span></p>
              <p class="c5"><span class="c2">The Company name, the terms Your Virtual Consult,</span></p>
              <p class="c5"><span></span></p>
              <p class="c3"><span class="c2">and all related names, logos, product and service names, designs and slogans are trademarks of the Company or its affiliates or licensors. You must not use such marks without the prior written permission of the
                  Company. All other names, logos, product and service names, designs and slogans on this Platform are the trademarks of their respective owners.</span></p>
              <p class="c7"><span class="c9">13. Prohibited Uses</span></p>
              <p class="c5"><span class="c2">You may use the Platform only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Platform:</span></p>
              <p class="c5"><span class="c2">In any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other
                  countries).</span></p>
              <p class="c5"><span class="c2">For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.</span></p>
              <p class="c5"><span class="c2">To send, knowingly receive, upload, download, use or re-use any material which does not comply with the </span><span class="c2 c15"><a class="c16"
                    href="#">Content Standards</a></span><span
                  class="c2">&nbsp;set out in these Terms or Use.</span></p>
              <p class="c5"><span class="c2">To transmit, or procure the sending of, any advertising or promotional material, including any &quot;junk mail&quot;, &quot;chain letter&quot; or &quot;spam&quot; or any other similar solicitation.</span></p>
              <p class="c5"><span class="c2">To impersonate or attempt to impersonate the Company, a Company employee, another user or any other person or entity (including, without limitation, by using e-mail addresses or screen names associated with any of the
                  foregoing).</span></p>
              <p class="c5"><span class="c2">To engage in any other conduct that restricts or inhibits anyone&#39;s use or enjoyment of the Platform, or which, as determined by us, may harm the Company or users of the Platform or expose them to liability.</span>
              </p>
              <p class="c5"><span class="c2">Additionally, you agree not to:</span></p>
              <p class="c5"><span class="c2">Copy, modify, adapt, translate, or reverse engineer the Platform or any portion of the Platform, its content, Information, or materials and/or the Services;</span></p>
              <p class="c5"><span class="c2">Use the Platform in any manner that could disable, overburden, damage, or impair the site or interfere with any other party&#39;s use of the Platform, including their ability to engage in real time activities through
                  the Platform;</span></p>
              <p class="c5"><span class="c2">Use any robot, spider or other automatic device, process or means to access the Platform for any purpose, including monitoring or copying any of the material on the Platform;</span></p>
              <p class="c5"><span class="c2">Use any manual process to monitor or copy any of the material on the Platform or for any other unauthorized purpose without our prior written consent;</span></p>
              <p class="c5"><span class="c2">Use any device, software or routine that interferes with the proper working of the Platform;</span></p>
              <p class="c5"><span class="c2">Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful;</span></p>
              <p class="c5"><span class="c2">Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Platform, the server on which the Platform is stored, or any server, computer or database connected to the Platform;</span>
              </p>
              <p class="c5"><span class="c2">Attack the Platform via a denial-of-service attack or a distributed denial-of-service attack;</span></p>
              <p class="c5"><span class="c2">Misuse the Services by scheduling an appointment which you have no intention of keeping;</span></p>
              <p class="c5"><span class="c4 c17">Create user accounts by automated means or under false or fraudulent pretenses;</span></p>
              <p class="c5"><span class="c4">Rent, retransmit, disclose, publish, sell, assign, lease, sublicense, market, or transfer the Platform or any portion thereof or use it in any manner not expressly authorized by these Terms of Use or by the express
                  written consent of the Company;</span></p>
              <p class="c5"><span class="c13 c22">R</span><span class="c4">eproduce, resell, or distribute the Platform or Services or any reports or data generated by the Platform or Services for any purpose without Company&rsquo;s written authorization;</span>
              </p>
              <p class="c5"><span class="c4">Offer or enable any third parties to use the Platform or Services through your account;</span></p>
              <p class="c5"><span class="c4">Display or otherwise publish the Platform or Services or any Content obtained therefrom;</span></p>
              <p class="c5"><span class="c4">Except as to providers seeking to establish relationships with prospective patients, generate income from the Platform or Services;</span></p>
              <p class="c5"><span class="c4">Use the Platform or Services for the development, production or marketing of a service or product substantially similar to the Platform or Services;</span></p>
              <p class="c5"><span class="c13">Engage in any activity or use the Platform or Services in any manner that could damage, disable, overburden, impair or otherwise interfere with or disrupt the Platform or Services, or any servers or networks
                  connected to the Platform or Services or Company&rsquo;s security systems;</span></p>
              <p class="c5"><span class="c2">Collect or store personal data about other users; or</span></p>
              <p class="c5"><span class="c2">Otherwise attempt to interfere with the proper working of the Platform.</span></p>
              <p class="c5"><span class="c2">Company will determine, in its sole discretion, whether you have engaged in prohibited conduct or have violated these Terms of Use. Company&#39;s determination will be final and unreviewable, and you waive any and all
                  rights you may have to challenge that decision.</span></p>
              <p class="c3"><span class="c2">In addition to Company&#39;s rights in these Terms of Use or under applicable law, Company may, but is not obligated to, take any legal action and implement any technical remedies to prevent the violation of this
                  provision and to enforce these Terms of Use.</span></p>
              <p class="c7"><span class="c9">14. User Feedback, Comments, and/or Contributions</span></p>
              <p class="c5"><span class="c2">The Platform may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, and other interactive features (collectively, &quot;</span><span class="c8">Interactive
                  Services</span><span class="c2">&quot;) that allow users to post, submit, publish, display or transmit to other users or other persons (hereinafter, &quot;</span><span class="c8">post</span><span class="c2">&quot;) content or materials
                  (collectively, &quot;</span><span class="c8">User Contributions</span><span class="c2">&quot;) on or through the Platform.</span></p>
              <p class="c5"><span class="c2">All User Contributions must comply with the </span><span class="c2 c15"><a class="c16"
                    href="#">Content Standards</a></span><span
                  class="c2">&nbsp;set out in these Terms of Use. Further, you must act responsibly when providing User Contributions. Do not post any information that another user, Provider, or any other individual or entity may use to identify you.</span></p>
              <p class="c5"><span class="c2">Any User Contribution you post to the site will be considered non-confidential and non-proprietary. By providing any User Contribution on the Platform, you grant us and our affiliates and service providers, and each
                  of their and our respective licensees, successors and assigns, an irrevocable, perpetual, royalty-free, fully sublicensable, fully paid up, worldwide license to use, reproduce, modify, publicly perform, publicly display, distribute and
                  otherwise disclose to third parties any such material for any purpose and to make adapt, edit, translate, prepare derivative works of, or incorporate into other works, your User Contribution. This license is non-exclusive, except you agree that
                  Company will have the exclusive right to practice this license to the extent of combining your User Contribution with the User Contributions of other Platform users for any reason.</span></p>
              <p class="c5"><span class="c2">You represent and warrant that:</span></p>
              <p class="c5"><span class="c2">You own or control all rights in and to the User Contributions and have the right to grant the license granted above to Company and our affiliates and service providers, and each of their and our respective licensees,
                  successors and assigns.</span></p>
              <p class="c5"><span class="c2">All of your User Contributions do and will comply with these Terms of Use.</span></p>
              <p class="c5"><span class="c2">You understand and acknowledge that you are solely responsible for any User Contributions you submit or contribute or User Contributions submitted using your account, and you, not the Company, have fully
                  responsibility for such content, including its legality, reliability, accuracy and appropriateness.</span></p>
              <p class="c3"><span class="c2">We are not responsible, or liable to any third party, for the content or accuracy of any User Contributions posted by you or any other user of the Platform.</span></p>
              <p class="c7"><span class="c9">15. Monitoring and Enforcement; Termination</span></p>
              <p class="c5"><span class="c2">We have the right, but not the obligation, to:</span></p>
              <p class="c5"><span class="c2">Remove or refuse to post any User Contributions for any or no reason in our sole discretion.</span></p>
              <p class="c5"><span class="c2">Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates the Terms of Use, including the
              </span><span class="c2 c15"><a class="c16" href="#">Content
                    Standards</a></span><span class="c2">, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Platform or the public or could create liability for the Company.</span>
              </p>
              <p class="c5"><span class="c2">Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.</span></p>
              <p class="c5"><span class="c2">Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Platform.</span></p>
              <p class="c5"><span class="c2">Terminate or suspend your access to all or part of the Platform, without notice, for any or no reason, including without limitation, any violation of these Terms of Use.</span></p>
              <p class="c5"><span class="c2">Terminate and/or suspend your registration, without notice, for any or no reason, including without limitation, any violation of these Terms of Use.</span></p>
              <p class="c5"><span class="c2">Company will not be liable to you or any third party for any termination of your access to the Platform and/or the Services. Further, you agree not to attempt to use the Platform and/or the Services after any such
                  termination. The following will survive any termination of these Terms of Use: Sections 3, 4, 5, 14, 15, 16, 18, 24, 25, 26, 27, 29, 30, 31 and any other sections which by their sense and context are intended to survive such termination.</span>
              </p>
              <p class="c5"><span class="c2">Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any
                  materials on or through the Platform. YOU WAIVE, HOLD HARMLESS, AND AGREE TO DEFEND THE COMPANY AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING OR AS A
                  RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER THE FOREGOING PARTIES OR LAW ENFORCEMENT AUTHORITIES.</span></p>
              <p class="c3"><span class="c2">However, we cannot and do not undertake to review any or all material before it is posted on the Platform, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no
                  liability for any action or inaction regarding transmissions, communications or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this
                  section.</span></p>
              <p class="c7"><span class="c9">16. Content Standards</span></p>
              <p class="c5"><span class="c2">These content standards apply to any and all User Contributions and use of Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local and international laws and
                  regulations. Without limiting the foregoing, User Contributions must not:</span></p>
              <p class="c5"><span class="c2">Contain any material which is false, misleading, defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable.</span></p>
              <p class="c5"><span class="c2">Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.</span></p>
              <p class="c5"><span class="c2">Infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person.</span></p>
              <p class="c5"><span class="c2">Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise
                  may be in conflict with these Terms of Use.</span></p>
              <p class="c5"><span class="c2">Be likely to deceive any person.</span></p>
              <p class="c5"><span class="c2">Promote any illegal activity, or advocate, promote or assist any unlawful act.</span></p>
              <p class="c5"><span class="c2">Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other person.</span></p>
              <p class="c5"><span class="c2">Impersonate any person or misrepresent your identity or affiliation with any person or organization.</span></p>
              <p class="c5"><span class="c2">Involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter or advertising.</span></p>
              <p class="c3"><span class="c2">Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.</span></p>
              <p class="c7"><span class="c9">17. Copyright Infringement</span></p>
              <p class="c3"><span class="c4 c17">You may not post, modify, distribute, or reproduce in any way copyrighted material, trademarks, rights of publicity or other proprietary rights without obtaining the prior written consent of the owner of such
                  proprietary rights. Company may deny access to the Platform to any user who is alleged to infringe another party&#39;s copyright.</span></p>
              <p class="c3"><span class="c2">If you believe that any User Contributions violate your copyright, please see Section 33 regarding Copyright Disputes for instructions on sending us a notice of copyright infringement. It is the policy of the Company
                  to terminate the user accounts of repeat infringers.</span></p>
              <p class="c7"><span class="c9">18. Reliance on Information Posted</span></p>
              <p class="c5"><span class="c2">The Information and Services presented/provided on or through the Platform is made available solely for general information purposes. We do not warrant the accuracy, completeness or usefulness of the Information or
                  Services. Any reliance you place on such Information or Services is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Platform, or by
                  anyone who may be informed of any of its contents.</span></p>
              <p class="c3"><span class="c2">This Platform includes content provided by third parties, including materials provided by other users, bloggers and third-party licensors, syndicators, aggregators and/or reporting services. All statements and/or
                  opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those
                  materials. These materials do not necessarily reflect the opinion of the Company. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.</span></p>
              <p class="c7"><span class="c9">19. Changes to the Platform and Services</span></p>
              <p class="c5"><span class="c2">We may update the content on this Platform from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Platform may be out of date at any given time, and we are under no
                  obligation to update such material.</span></p>
              <p class="c3"><span class="c2">We may change the Services provided on the Platform from time to time, including, but not limited to, adding new services, substituting a new service for one of the existing Services, or discontinuing or suspending
                  one of the existing services. Use of any new or modified Services will be governed by these Terms of Use. Company will not be liable to you or to any third party for any modification, suspension, or termination of any of the Services.</span>
              </p>
              <p class="c7"><span class="c9">20. Additional&nbsp;Terms and Conditions</span></p>
              <p class="c3"><span class="c2">Additional terms and conditions may also apply to specific portions, services or features of the Platform. Those additional terms will govern your use of the particular Service to which the additional terms apply. By
                  using the particular Service to which the additional terms apply, you agree to the additional terms. If you do not agree to the additional terms, then you must not use the Services to which the additional terms apply. All such additional terms
                  and conditions are hereby incorporated by this reference into these Terms of Use. In the event of an irreconcilable difference between the additional terms and these terms of use, the additional terms will apply.</span></p>
              <p class="c7"><span class="c9">21. Linking to the Platform and Social Media Features</span></p>
              <p class="c5"><span class="c2">You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of
                  association, approval or endorsement on our part without our express, written consent.</span></p>
              <p class="c5"><span class="c2">This Platform may provide certain social media features that enable you to:</span></p>
              <p class="c5"><span class="c2">Link from your own or certain third-party Platforms to certain content on this Platform.</span></p>
              <p class="c5"><span class="c2">Send e-mails or other communications with certain content, or links to certain content, on this Platform.</span></p>
              <p class="c5"><span class="c2">Cause limited portions of content on this Platform to be displayed or appear to be displayed on your own or certain third-party Platforms.</span></p>
              <p class="c5"><span class="c2">You may use these features solely as they are provided by us and solely with respect to the content they are displayed with and otherwise in accordance with any additional terms and conditions we provide with respect
                  to such features. Subject to the foregoing, you must not:</span></p>
              <p class="c5"><span class="c2">Establish a link from any Platform that is not owned by you.</span></p>
              <p class="c5"><span class="c2">Cause the Platform or portions of it to be displayed, or appear to be displayed by, for example, framing, deep linking or in-line linking, on any other site.</span></p>
              <p class="c5"><span class="c2">Link to any part of the Platform other than the homepage unless you are a contractor with Company authorization to market and promote the Platform.</span></p>
              <p class="c5"><span class="c2">Otherwise take any action with respect to the materials on this Platform that is inconsistent with any other provision of these Terms of Use.</span></p>
              <p class="c5"><span class="c2">The Platform from which you are linking, or on which you make certain content accessible, must comply in all respects with the </span><span class="c2 c15"><a class="c16"
                    href="#">Content Standards</a></span><span
                  class="c2">&nbsp;set out in these Terms of Use.</span></p>
              <p class="c5"><span class="c2">You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking permission without notice.</span></p>
              <p class="c3"><span class="c2">We may disable all or any social media features and any links at any time without notice in our discretion.</span></p>
              <p class="c7"><span class="c9">22. Links from the Platform</span></p>
              <p class="c3"><span class="c2">If the Platform contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner
                  advertisements and sponsored links, and any links provided as automated search results. Company has no control over the contents of those sites or resources, and accepts no responsibility for them or for any loss or damage that may arise from
                  your use of them. Some of these sites may contain materials that are objectionable, unlawful, or inaccurate. If you decide to access any of the third party Platforms linked to this Platform, you do so entirely at your own risk and subject to
                  the terms and conditions of use for such Platforms. You acknowledge and agree that Company is not responsible or liable for the content or accuracy of any other site.</span></p>
              <p class="c7"><span class="c9">23. Geographic Restrictions; Legal Compliance with Export Laws</span></p>
              <p class="c5"><span class="c2">The owner of the Platform is based in the state of California in the United States. Company provides this Platform for use only by persons located in the United States. Company makes no claims that the Platform or any
                  of its content is accessible or appropriate outside of the United States. Access to the Platform may not be legal by certain persons or in certain countries. If you access the Platform from outside the United States, you do so on your own
                  initiative and are responsible for compliance with local laws.</span></p>
              <p class="c3"><span class="c2">You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a &quot;terrorist supporting&quot; country; and
                  (ii) you are not listed on any U.S. Government list of prohibited or restricted parties. You further agree to comply with all applicable laws regarding the transmission of technical data exported from the United States or the country in which
                  you reside.</span></p>
              <p class="c7"><span class="c9">24. Disclaimer of Warranties</span></p>
              <p class="c5"><span class="c2">You agree that you are solely responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for
                  maintaining a means external to the Platform for any reconstruction of any lost data.</span></p>
              <p class="c5"><span class="c2">You acknowledge and agree that Company has no control over, and no duty to take any action regarding: which users gain access to the Platform and/or the Services; what content you access via the Platform and/or the
                  Services; what effects the content on the Platform and/or the Services may have on you; how you may interpret or use the Information or User Contributions or other content on the Platform and/or the Services; or what actions you may take as a
                  result of having been exposed to the Information, User Contributions or other content on the Platform and/or Services. You release Company from any and all liability for you having acquired or not acquired Information, User Contributions or
                  other content through the Platform and/or the Services. The Platform and/or the Services may contain, or direct you to Platforms containing, information that some people may find offensive or inappropriate or contain inaccurate or misleading
                  information. Company makes no representations concerning any Information, User Contributions or other content on or accessed through the Platform and/or the Services, and Company will not be responsible or liable for the accuracy, copyright
                  compliance, legality or decency of material contained on or accessed through the Platform and/or the Services. Company makes no representations or warranties regarding suggestions or recommendations of services or products offered or purchased
                  through the Platform and/or the Services.</span></p>
              <p class="c5"><span class="c2">Further, you understand that Company cannot and does not guarantee or warrant that files available for downloading from the internet or the Platform will be free of viruses or other destructive code. You are
                  responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction
                  of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER
                  PROPRIETARY MATERIAL DUE TO YOUR USE OF THE PLATFORM OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY PLATFORM LINKED TO IT.</span></p>
              <p class="c5"><span class="c2">YOUR USE OF THE PLATFORM, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM IS AT YOUR OWN RISK. THE PLATFORM, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM ARE PROVIDED ON AN
                  &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
                  COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE PLATFORM. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE PLATFORM, ITS CONTENT OR ANY
                  SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT
                  THE PLATFORM OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. COMPANY DOES NOT GUARANTEE THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE PLATFORM OR SERVICES WILL BE EFFECTIVE,
                  RELIABLE OR ACCURATE OR WILL MEET YOUR REQUIREMENTS. EXCEPT AS EXPRESSLY SET FORTH HEREIN, COMPANY MAKES NO WARRANTIES ABOUT THE INFORMATION SYSTEMS, SOFTWARE AND FUNCTIONS MADE ACCESSIBLE THROUGH THE PLATFORM OR ANY OTHER SECURITY ASSOCIATED
                  WITH THE TRANSMISSION OF SENSITIVE DATA OR INFORMATION.</span></p>
              <p class="c5"><span class="c2">THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR
                  PURPOSE, COURSE OF DEALING OR COURSE OF PERFORMANCE.</span></p>
              <p class="c3"><span class="c4 c17">THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</span></p>
              <p class="c3"><span class="c4">The foregoing waivers of warranties notwithstanding, by using the Platform and Services, you represent and warrant that: (a) your use of the Platform and Services shall not violate any law, statute, ordinance or
                  regulation, including without limitation the laws and regulations governing export control, unfair competition, antidiscrimination or false advertising; (b) content created by you shall not contain defamatory, trade libelous, unlawfully
                  threatening, obscene, child pornographic or indecent matter; (c) you will not take any actions that are intended to damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or information from the
                  Platform; (d) your actual use of the Services or Platform will not infringe the intellectual property rights of any third party; (e) there is no pending or threatened litigation that would have a material adverse impact on your compliance with
                  these terms of use; and (f) you shall comply with all applicable laws and regulations in your use of the Platform and Services.</span></p>
              <p class="c3 c24"><span class="c4"></span></p>
              <p class="c3"><span class="c4">To the extent applicable in these Terms of Use, you represent, warrant, and covenant that your use of the Platform and Services shall comply with all applicable rules, regulations and accreditation standards or
                  requirements of: Medicare or Medicaid or other federal or state health programs, the Joint Commission on Accreditation of Healthcare Organizations; the Health Insurance Portability and Accountability Act of 1996 (&ldquo;HIPAA&rdquo;); the
                  National Committee for Quality Assurance; updates to incorporate any changes to such laws, rules, regulations, requirements and standards.</span></p>
              <p class="c7"><span class="c9">25. Limitation on Liability</span></p>
              <p class="c5"><span class="c2">IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION
                  WITH YOUR USE, OR INABILITY TO USE, THE PLATFORM, ANY PLATFORMS LINKED TO IT, ANY CONTENT ON THE PLATFORM OR SUCH OTHER PLATFORMS OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM OR SUCH OTHER PLATFORMS, INCLUDING ANY DIRECT, INDIRECT,
                  SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
                  GOODWILL, LOSS OF DATA, MEDICAL MALPRACTICE, OR NEGLIGENCE OF PROVIDERS UTILIZED THROUGH THE SERVICES AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE BUT NOT INTENTIONAL CONDUCT), BREACH OF CONTRACT OR OTHERWISE, EVEN IF COMPANY KNEW OR SHOULD
                  HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES.</span></p>
              <p class="c5"><span class="c2">YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE RELATING TO THE PLATFORM, ANY SERVICES OBTAINED ON OR THROUGH THE PLATFORM, OR ANY CONTENT, INFORMATION OR USER CONTRIBUTIONS ON THE PLATFORM OR IN ANY OTHER WAY RELATED
                  TO THE PLATFORM OR THESE TERMS OF USE IS THE CANCELLATION OF YOUR REGISTRATION. IN NO EVENT WILL COMPANY&#39;S TOTAL CUMULATIVE LIABILITY TO YOU FOR ANY AND ALL CLAIMS RELATING TO OR ARISING OUT OF YOUR USE OF THE SERVICES OR THE PLATFORM,
                  REGARDLESS OF THE FORM OF ACTION, EXCEED THE GREATER OF: (A) THE TOTAL AMOUNT OF FEES, IF ANY, THAT YOU PAID TO REGISTER FOR OR ACCESS THE PLATFORM OR SERVICES OVER THE PAST SIX (6) MONTHS OR (B) $100.00.</span></p>
              <p class="c3"><span class="c2">THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW. IN THE STATES OR JURISDICTIONS WHICH DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
                  INCIDENTAL DAMAGES, COMPANY&#39;S LIABILITY WILL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW. IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE YOUR RIGHTS WITH RESPECT TO CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS &quot;A GENERAL RELEASE
                  DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR WHICH, IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.&quot;</span></p>
              <p class="c7"><span class="c9">26. Indemnification</span></p>
              <p class="c3"><span class="c2">You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers,
                  successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys&#39; fees) arising out of or relating to your violation of these Terms of Use or your
                  use of the Platform, including, but not limited to, your User Contributions, any use of the Platform&#39;s content, the Services and products offered on or through the Platform, or your use of any information obtained from the Platform, or the
                  violation of any intellectual property or other right of any person or entity, by your or any third party using your registration information. The foregoing indemnification obligation does not apply to liabilities, claims, and expenses arising
                  as a result of our own gross negligence or intentional misconduct.</span></p>
              <p class="c7"><span class="c9">27. Governing Law and Jurisdiction</span></p>
              <p class="c5"><span class="c2">All matters relating to the Platform and these Terms of Use and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), will be governed by and construed
                  in accordance with the internal laws of the State of Delaware without giving effect to any choice or conflict of law provision or rule (whether of the State of Delaware or any other jurisdiction).</span></p>
              <p class="c3"><span class="c4 c17">Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the Platform will be instituted exclusively in the federal courts of the United States or the courts of the State of
                  California, in each case located in the City of San Diego, although we retain the right to bring any suit, action or proceeding against you for breach of these Terms of Use in your state of residence or any other relevant state. You waive any
                  and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</span></p>
              <p class="c3"><span class="c4 c17">Any proceedings to resolve or litigate any dispute in any forum in connection with this Agreement shall be conducted solely on an individual basis. Neither you nor Company shall seek to have any dispute or
                  controversy in connection with any use of the Platform &nbsp;heard as a class action or in any other proceeding in which either party acts or proposes to act in a representative capacity. Parties further agree that no arbitration or proceeding
                  shall be combined with another arbitration or proceeding without the prior written consent of the other party.</span></p>
              <p class="c7"><span class="c9">28. Limitation on Time to File Claims</span></p>
              <p class="c3"><span class="c2">ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE PLATFORM MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR
                  CLAIM IS PERMANENTLY BARRED.</span></p>
              <p class="c7"><span class="c9">29. Waiver and Severability</span></p>
              <p class="c5"><span class="c2">No waiver of by the Company of any term or condition set forth in these Terms of Use will be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure
                  of the Company to assert a right or provision under these Terms of Use will not constitute a waiver of such right or provision.</span></p>
              <p class="c3"><span class="c2">If any provision of these Terms of Use is held by a court, or other tribunal of competent jurisdiction, to be invalid, illegal or unenforceable for any reason, such provision will be eliminated or limited to the
                  minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.</span></p>
              <p class="c7"><span class="c9">30. Entire Agreement</span></p>
              <p class="c3"><span class="c2">These Terms of Use and any additional terms under Section 20 constitute the sole and entire agreement between you and Your Virtual Consult, LLC with respect to the Platform and supersede all prior and contemporaneous
                  understandings, agreements, representations and warranties, both written and oral, with respect to the Platform.</span></p>
              <p class="c7"><span class="c9">31. Electronic Contracting and Notices</span></p>
              <p class="c3"><span class="c2">Your affirmative act of using this Platform and/or registering for the Platform or the Services constitutes your electronic signature to these Terms of Use, and you consent to enter into agreements with Company
                  electronically.</span></p>
              <p class="c7"><span class="c9">32. Copyright Disputes</span></p>
              <p class="c5"><span class="c2">It is Company&#39;s policy to (a) block access to or remove material that it believes in good faith to be copyrighted material that has been illegally copied and distributed by any of our advertisers, affiliates,
                  content providers, members or users; and (b) remove and discontinue Service to repeat offenders.</span></p>
              <p class="c5"><span class="c2">If you believe that material or content residing on or accessible through the Platform or the Services infringes a copyright, please send a notice of copyright infringement containing the following information to the
                  designated agent listed below (&quot;Infringement Notice&quot;):</span></p>
              <ul class="c18 lst-kix_list_2-0 start">
                <li class="c6 li-bullet-0"><span class="c2">a physical or electronic signature of a person authorized to act on behalf of the owner of the copyright that has been allegedly infringed;</span></li>
                <li class="c6 li-bullet-0"><span class="c2">identification of the works or materials being infringed;</span></li>
                <li class="c6 li-bullet-0"><span class="c2">identification of the material that is claimed to be infringing, including information regarding the location of the infringing materials that the copyright owner seeks to have removed, with sufficient
                    detail so that Company is capable of finding and verifying its existence;</span></li>
                <li class="c6 li-bullet-0"><span class="c2">contact information about the notifier including address, telephone number and, if available, email address;</span></li>
                <li class="c6 li-bullet-0"><span class="c2">a statement that the notifier has a good faith belief that the material is not authorized by the copyright owner, its agent, or the law; and</span></li>
                <li class="c6 li-bullet-0"><span class="c2">a statement made under penalty of perjury that the information provided is accurate and the notifying party is authorized to make the complaint on behalf of the copyright owner.</span></li>
              </ul>
              <p class="c5"><span class="c2">Upon receipt of an Infringement Notice by the designated agent listed below, Company will remove or disable access to the infringing material and notify the content provider, member or user that it has removed or
                  disabled access to the material. For repeat offenders, Company will also terminate such content provider&#39;s member&#39;s or user&#39;s access to the Services.</span></p>
              <p class="c5"><span class="c2">If the content provider, member or user believes that the material that was removed or to which access was disabled is either not infringing, or the content provider, member or user believes that it has the right to
                  post and use such material from the copyright owner, the copyright owner&#39;s agent, or pursuant to the law, the content provider, member or user must send a counter-notice containing the following information to the designated agent listed
                  below:</span></p>
              <ul class="c18 lst-kix_list_3-0 start">
                <li class="c6 li-bullet-0"><span class="c2">a physical or electronic signature of the content provider, member or user;</span></li>
                <li class="c6 li-bullet-0"><span class="c2">identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or disabled;</span></li>
                <li class="c6 li-bullet-0"><span class="c2">a statement that the content provider, member or user has a good faith belief that the material was removed or disabled as a result of mistake or a misidentification of the material; and</span></li>
                <li class="c6 li-bullet-0"><span class="c2">the content provider&#39;s, member&#39;s or user&#39;s name, address, telephone number, and, if available, email address and a statement that such person or entity consents to the jurisdiction of the
                    Federal Court for the judicial district in which the content provider&#39;s, member&#39;s or user&#39;s address is located, or if the content provider&#39;s, member&#39;s or user&#39;s address is located outside the United States, for any
                    judicial district in which Company is located, and that such person or entity will accept service of process from the person who provided notification of the alleged infringement.</span></li>
              </ul>
              <p class="c5"><span class="c2">If a counter-notice is received by the designated agent listed below, Company may send a copy of the counter-notice to the original complaining party, informing that person that Company may replace the removed
                  material or cease disabling the material in 10 business days. Unless the copyright owner files an action seeking a court order against the content provider, member or user, the removed material or disabled material may be replaced or access to
                  the material may be restored in 10 to 14 business days or more after the receipt of the counter-notice, at Company&#39;s discretion. However, Company is not obligated to replace or restore access to any material.</span></p>
              <p class="c5"><span class="c2">Company&#39;s designated agent to receive any notification listed in this section is:</span></p>
              <p class="c12"><span class="c2">Paracorp Incorporated</span></p>
              <p class="c12"><span class="c2">2140 S. DuPont Highway</span></p>
              <p class="c12"><span class="c2">Camden, Delaware 19934</span></p>
              <p class="c10"><span class="c0"></span></p>
              <p class="c7"><span class="c9">33. Assignment</span></p>
              <p class="c3"><span class="c2">Company may assign the Terms of Use or any or all of its obligations under these Terms of Use at any time with no notice to you. You may not assign, transfer, or sublicense these Terms of Use or any of your
                  obligations under these Terms of Use and any attempt to do so in violation of this section will be null and void.</span></p>
              <p class="c26"><span class="c9">34. Your Comments and Concerns</span></p>
              <p class="c19"><span class="c2">The Platform is operated by Your Virtual Consult, LLC 3015 St Charles St Ste. B, San Diego, CA 92110.</span></p>
              <p class="c19"><span class="c2">All other feedback, comments, requests for technical support and other communications relating to the Platform should be directed to:</span></p>
              <p class="c19"><span class="c2">hello@yourvirtualconsult.com</span></p>
              <p class="c25"><span class="c2">Thank you for using the Platform.</span></p>
              <p class="c23"><span class="c0"></span></p>
            </div>
            {organization && organization.terms_addendum && (
              <div>
                <hr className="my-5" />
                <h1 className="mb-3">{organization.name} Addendum</h1>
                { organization && organization.terms_addendum && <div>{Parser(organization.terms_addendum)}</div>}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    )
  }

}

export default Success
